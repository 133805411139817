import Modal from 'react-modal'
import { ReactComponent as Times } from '../../../../assets/times.svg'

const styles = {
  content: {
    inset: '16px',
    borderRadius: '20px',
    width: '450px',
    height: 'fit-content',
    margin: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function MultipieceCustomerPrompt ({
  isOpen,
  customer,
  onClose,
  onProceed
}) {
  return (
    <Modal
      style={styles}
      isOpen={isOpen}
      onRequestClose={onClose}
      appElement={document.getElementById('root')}
    >
      <div className='text-center'>
        <button
          className='w-9 h-9 hover:border-solid hover:border-[0.75px] hover:border-gray-500 cursor-pointer ml-auto flex'
          onClick={onClose}
        >
          <Times />
        </button>
        <h3 className='text-lg text-dark-primary font-medium mb-3'>
          Multipiece order detected
        </h3>
        <div className='mb-5'>
          <p>
            You're creating a multipiece order. The bill-to customer has been
            set as the <span className='font-medium'>{customer}</span>.
            Therefore, you're not able to change the{' '}
            <span className='font-medium'>{customer}</span> <br /> <br />
          </p>
          <p>You may proceed to the next step.</p>
        </div>
        <div>
          <button className='btn btn-primary w-full' onClick={onProceed}>
            Proceed
          </button>
        </div>
      </div>
    </Modal>
  )
}
