import axios from 'axios'

const URL = 'https://nigerianbanks.xyz'

const client = axios.create({
  baseURL: URL,
  timeout: process.env.REACT_APP_API_TIMEOUT
})

client.interceptors.response.use(
  response => {
    response.ok = true
    return response
  },
  error => {
    if (error.response) {
      error.response.ok = false
    }

    return error
  }
)

const getBanks = async () => {
  const response = await client.get(URL)

  return response
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getBanks
}
