import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import ordersApi from '../api/orders'
import { parseError } from '../utils'

const initialState = {
  data: null,
  error: null
}

export const fetchAddons = createAsyncThunk('addons/fetch', async () => {
  const response = await ordersApi.getAddons()

  if (!response.ok) {
    const apiError = parseError(response)
    if (apiError) {
      return {
        type: 'error',
        error: apiError
      }
    }
  }

  return {
    type: 'success',
    data: response.data[0].payload
  }
})

const addonsSlice = createSlice({
  name: 'addons',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchAddons.fulfilled, (state, action) => {
      if (action.payload.type === 'success') {
        state.data = action.payload.data
        state.error = null
      } else {
        state.error = action.payload.error
        state.data = null
      }
    })
  }
})

export const addonsActions = addonsSlice.actions

export default addonsSlice.reducer
