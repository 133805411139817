import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom'

export default function useNavigateWithParams () {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const serializeParams = newParams => {
    const validParams = {}

    const oldParams = Object.fromEntries(searchParams.entries())

    const params = Object.assign({}, oldParams, newParams)

    for (const key in params) {
      if (
        params[key] !== '' &&
        params[key] !== undefined &&
        params[key] !== null
      )
        validParams[key] = params[key]
    }

    return validParams
  }

  const createSearchString = search => {
    const searchString = createSearchParams(serializeParams(search))
    return searchString ? `?${searchString}` : ''
  }

  return (to, options = {}) => {
    navigate(
      {
        ...to,
        search: createSearchString(to.search)
      },
      options
    )
  }
}
