import { useRef, useEffect, useMemo, useState } from 'react'
import { IoMdAdd } from 'react-icons/io'
import useNavigateWithParams from '../../../../hooks/useNavigateWithParams'
import ROUTES from '../../../../constants/routes'
import { twMerge } from 'tailwind-merge'
import { headerShadowOnScroll } from '../../../../utils/animate'
import MultipieceBillTo from './MultipieceBillTo'
import { MdOutlineClear } from 'react-icons/md'
import ordersApi from '../../../../api/orders'
import { parseError } from '../../../../utils'
import useToast from '../../../../hooks/useToast'
import { CircularProgress } from '@mui/material'
import MultipieceOrderRemovePrompt from './MultipieceOrderRemovePrompt'
import Pill from '../../../globals/Pill'
import { statuses } from '../../../../fixtures/orderStatus'

const OrderCard = ({
  order,
  isActive,
  onClick,
  onConfirmRemoveOrder,
  isRemoving,
  multipieceLength,
  showStatus
}) => {
  const canRemove = useMemo(() => {
    return (
      multipieceLength > 1 &&
      order?.order.order_state === 1 &&
      !order?.order.paid
    )
  }, [multipieceLength, order])

  const handleRemove = e => {
    e.stopPropagation()
    onConfirmRemoveOrder(order.order.id)
  }

  return order ? (
    <div
      className={twMerge(
        'rounded-[4px] bg-white border min-h-24 w-fit lg:w-full hover:shadow-md cursor-pointer select-none lg:text-[13px] relative overflow-x-hidden',
        isActive ? 'border-primary' : ''
      )}
      onClick={isRemoving ? null : onClick}
    >
      <div className='border-b p-1 flex items-center gap-2 justify-between'>
        <span className='font-medium'>#{order.order.id}</span>
        {showStatus && (
          <Pill
            name={statuses[order.order.order_state].name}
            theme={statuses[order.order.order_state].theme}
          />
        )}
        {canRemove && (
          <button
            className='text-dark-primary transition-all w-6 h-6 flex items-center justify-center bg-white hover:bg-g-500 rounded-full'
            onClick={handleRemove}
          >
            <MdOutlineClear size={16} />
          </button>
        )}
      </div>
      <div className='py-1 px-1.5 space-y-1'>
        <div className='flex items-center gap-2'>
          <span>{order.order.weight}kg</span> •{' '}
          <span>{order.order.tpl_service}</span>
        </div>
        <div>
          <span>
            {order.order.total?.toLocaleString('en-NG', {
              style: 'currency',
              currency: 'NGN'
            })}
          </span>
        </div>
      </div>
      {isRemoving && (
        <div className='absolute top-0 left-0 w-full h-full flex items-center justify-center bg-white/50 z-50'>
          <CircularProgress size={32} style={{ color: '#FF4D00' }} />
          <MdOutlineClear
            size={16}
            color='#FF4D00'
            className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
          />
        </div>
      )}
    </div>
  ) : (
    <div className='card is-loading h-24 lg:h-20 w-fit lg:w-full'></div>
  )
}

export default function MultipieceOrders ({
  from,
  multipiece,
  billTo,
  setBillTo,
  orders,
  activeOrder,
  onOrderClick,
  onPaymentProceed,
  onReload
}) {
  const [openBillTo, setOpenBillTo] = useState()
  const [isConfirmRemove, setConfirmRemove] = useState({
    state: false,
    order: ''
  })
  const [isRemoving, setRemoving] = useState(true)

  const navigateWithParams = useNavigateWithParams()

  const stickyHeader = useRef(null)

  const toast = useToast()

  const isPaid = useMemo(() => {
    return orders[0]?.order.paid
  }, [orders])

  const customerName = useMemo(() => {
    return orders[0]?.order[billTo]?.contact.name
  }, [billTo, orders])

  useEffect(() => {
    if (stickyHeader.current) {
      headerShadowOnScroll(stickyHeader.current)
    }
  }, [])

  const handlePaymentBillTo = () => {
    setOpenBillTo({
      state: true,
      nextActionType: 'payment',
      nextActionText: 'Proceed to Payment',
      nextAction: onPaymentProceed
    })
  }

  const handleAddOrderBillTo = () => {
    setOpenBillTo({
      state: true,
      nextActionType: 'add-order',
      nextActionText: 'Proceed to Add Order',
      nextAction: handleAddOrder
    })
  }

  const handleAddOrder = async (bill_to = billTo) => {
    const billToCustomer = orders[0].order[bill_to]
    const bill_to_id = billToCustomer.customer_id

    window.sessionStorage.removeItem('order')

    navigateWithParams(
      {
        pathname: `${ROUTES.ORDERS.CREATE_ORDER.path}${ROUTES.ORDERS.CREATE_ORDER.SHIPMENT_TYPE.path}`,
        search: {
          mpo: multipiece.id,
          bill_to,
          bill_to_id
        }
      },
      {
        state: {
          payload: {
            sender: bill_to === 'sender' ? billToCustomer : null,
            receiver: bill_to === 'receiver' ? billToCustomer : null,
            type: '',
            package_insurance: '',
            packages: null,
            tpl_service: '',
            description: '',
            draft: true,
            category: null,
            delivery_mode: '',
            delivery_type: '',
            pickub_hub: null,
            insured_value: null,
          }
        }
      }
    )
  }

  const confirmRemoveOrder = order_id => {
    setConfirmRemove({ state: true, order: order_id })
  }

  const onConfirmRemoveOrder = async () => {
    const order_id = isConfirmRemove.order
    setRemoving({ state: true, order: order_id })
    setConfirmRemove({ state: false, order: '' })

    const response = await ordersApi.updateMultipiece({
      action: 'remove_order',
      orders: [isConfirmRemove.order],
      multi_piece_id: multipiece.id
    })

    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        toast('Unable to remove order from multipiece', 'error')
      }
      return
    }

    toast('Removed order from multipiece', 'success')

    // change active order if is order
    if (activeOrder.order.id === order_id) {
      const order_index = orders.findIndex(({ order }) => {
        return order.id === order_id
      })
      switch (order_index) {
        case 0:
          // is first
          onOrderClick(order_index + 1)
          break
        case orders.length - 1:
          // is last
          onOrderClick(order_index - 1)
          break
        case -1:
          break
        default:
          // is in-between; set to previous
          onOrderClick(order_index - 1)
      }
    }

    // reload multipiece
    onReload()

    setRemoving({ state: false, order: '' })
  }

  const onDeclineRemove = () => {
    setConfirmRemove({ state: false, order: '' })
  }

  return (
    <div className='bg-white lg:rounded-lg lg:border divide-y lg:text-sm'>
      {openBillTo && (
        <MultipieceBillTo
          isOpen={openBillTo.state}
          onClose={() => setOpenBillTo(false)}
          order={activeOrder.order}
          updateBillTo={setBillTo}
          nextActionType={openBillTo.nextActionType}
          actionText={openBillTo.nextActionText}
          nextAction={openBillTo.nextAction}
        />
      )}
      {isConfirmRemove.state && (
        <MultipieceOrderRemovePrompt
          isOpen={isConfirmRemove.state}
          order={isConfirmRemove.order}
          onConfirm={onConfirmRemoveOrder}
          onDecline={onDeclineRemove}
        />
      )}
      <div className='p-1.5'>
        <h4 className='font-medium lg:text-center'>Multipiece orders</h4>
      </div>
      <div className='lg:min-h-40 lg:max-h-[70vh] lg:overflow-y-auto'>
        <div
          className='lg:text-[13px] text-dark-primary flex flex-row flex-wrap items-center lg:items-start lg:flex-col gap-2 lg:gap-0 lg:sticky-header py-1 px-1.5 bg-white'
          ref={stickyHeader}
        >
          <div>
            <span className='mr-1'>Total ({orders.length}):</span>
            <span>
              {multipiece?.total?.toLocaleString('en-NG', {
                style: 'currency',
                currency: 'NGN'
              })}
            </span>
          </div>
          <div className='flex items-center gap-2'>
            <span className='sm:hidden'>•</span>
            <div>
              <span className='mr-1'>Bill to:</span>
              <span>{customerName}</span>
            </div>
          </div>
          {isPaid && (
            <div className='flex items-center gap-2'>
              <span className='lg:hidden'>•</span>
              <div className='flex flex-row items-center'>
                <div className='bg-[#00FF66] w-2 h-2 rounded-xl mr-0.5' />
                <div className='font-medium text-[#00AB44]'>Paid</div>
              </div>
            </div>
          )}
        </div>
        <div className='py-2 lg:pt-1 lg:pb-4 px-1.5 space-x-1 lg:space-x-0 flex w-full overflow-x-auto lg:space-y-1 lg:block lg:overflow-x-hidden'>
          {orders.map((order, index) => (
            <OrderCard
              key={index}
              order={order}
              isActive={activeOrder.order.id === order?.order?.id}
              onClick={() => onOrderClick(index)}
              onConfirmRemoveOrder={confirmRemoveOrder}
              isRemoving={
                isRemoving.state && isRemoving.order === order.order.id
              }
              multipieceLength={orders.length}
              showStatus={from === 'process-order'}
            />
          ))}
        </div>
      </div>
      <div className='grid grid-cols-2 lg:grid-cols-1 gap-2 pt-3 px-1.5 lg:p-1.5'>
        {!isPaid && (
          <button
            className='btn btn-secondary w-full'
            onClick={billTo ? () => handleAddOrder() : handleAddOrderBillTo}
            disabled={!multipiece}
          >
            <IoMdAdd size={14} className='mr-1' />
            Add Order
          </button>
        )}
        <button
          className='btn btn-primary w-full'
          onClick={billTo ? () => onPaymentProceed() : handlePaymentBillTo}
          disabled={!multipiece}
        >
          {isPaid ? 'Process All' : 'Proceed to Payment'}
        </button>
      </div>
    </div>
  )
}
