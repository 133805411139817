import Modal from 'react-modal'
import { ReactComponent as Times } from '../../assets/times.svg'
import { capitalizeFirstLetter } from '../../utils'

const styles = {
  content: {
    inset: '16px',
    borderRadius: '20px',
    maxWidth: '450px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%',
    overflow: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function CustomerPreview ({
  isOpen,
  onClose,
  type = 'Sender',
  customer,
  allowEdit,
  onEdit,
  onProceed
}) {
  return (
    <Modal
      style={styles}
      isOpen={isOpen}
      onRequestClose={onClose}
      appElement={document.getElementById('root')}
    >
      <div>
        <div
          className='w-9 h-9 hover:border-solid hover:border-[0.75px] hover:border-gray-500 cursor-pointer ml-auto'
          onClick={onClose}
        >
          <Times />
        </div>
        <div>
          <h3 className='text-lg text-dark-primary font-medium text-center mb-3'>{`Preview ${capitalizeFirstLetter(
            type
          )}`}</h3>
          <div className='divide-y divide-g-200 border-b border-g-200 pb-2 mb-2'>
            <div className='flex items-center w-full justify-between py-2'>
              <span>Name:</span>
              <span className='text-right'>{customer?.full_name}</span>
            </div>
            <div className='flex items-center w-full justify-between py-2'>
              <span>Email:</span>
              <span className='text-right'>{customer?.email}</span>
            </div>
            <div className='flex items-center w-full justify-between py-2'>
              <span>Phone Number:</span>
              <span className='text-right'>{customer?.phone_number}</span>
            </div>
            <div className='flex items-center w-full justify-between py-2'>
              <span>Address:</span>
              <span className='text-right'>{customer?.address}</span>
            </div>
            <div className='flex items-center w-full justify-between py-2'>
              <span>Landmark:</span>
              <span className='text-right'>{customer?.alt_address}</span>
            </div>
            <div className='flex items-center w-full justify-between py-2'>
              <span>Postcode:</span>
              <span className='text-right'>{customer?.postcode}</span>
            </div>
            <div className='flex items-center w-full justify-between py-2'>
              <span>City:</span>
              <span className='text-right'>{customer?.city}</span>
            </div>
            {customer?.code === 'NG' && (
              <div className='flex items-center w-full justify-between py-2'>
                <span>Province:</span>
                <span className='text-right'>{customer?.area_or_province}</span>
              </div>
            )}
            <div className='flex items-center w-full justify-between py-2'>
              <span>State:</span>
              <span className='text-right'>{customer?.state}</span>
            </div>
            <div className='flex items-center w-full justify-between py-2'>
              <span>Country:</span>
              <span className='text-right'>
                {customer?.country} ({customer?.code})
              </span>
            </div>
            <div className='flex items-center w-full justify-between py-2'>
              <span>Business Name:</span>
              <span className='text-right'>{customer?.business_name}</span>
            </div>
            <div className='flex items-center w-full justify-between py-2'>
              <span>Business Contact:</span>
              <span className='text-right'>{customer?.business_contact}</span>
            </div>
            <div className='flex items-center w-full justify-between py-2'>
              <span>Customer type:</span>
              <span className='text-right'>
                {customer?.partner ? 'Partner' : 'Regular'}
              </span>
            </div>
          </div>
          <div>
            <div className='flex items-center w-full justify-end gap-4'>
              {allowEdit && (
                <button
                  className='btn btn-sm btn-secondary'
                  onClick={() => onEdit(customer)}
                >
                  Edit customer
                </button>
              )}
              <button
                className='btn btn-sm btn-primary'
                onClick={() => onProceed(customer)}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
