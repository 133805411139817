import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import logo from '../../../assets/logo.png'
import redstar from '../../../assets/redstar.png'
import Modal from 'react-modal'
import noDataIcon from '../../../assets/no-data.webp'
import { CircularProgress } from '@mui/material'
import { ReactComponent as Check } from '../../../assets/check.svg'
import { ReactComponent as Times } from '../../../assets/times.svg'
import { capitalizeFirstLetter } from '../../../utils'
import { fetchAllManifestShipments } from '../../../slices/allInManifestShipments'

const styles = {
  content: {
    inset: '16px',
    borderRadius: '20px',
    maxWidth: '450px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%',
    overflow: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export const CreateCenterModal = ({
  centerModal,
  setCenterModal,
  createManifest,
  setDestId,
  allHubs
}) => {
  const dispatch = useDispatch()
  const shipmentsStore = useSelector(state => state.allShipments)
  const userData = useSelector(state => state.auth.user)
  const [disabled, setDisabled] = useState(true)
  const [typeCenter, setTypeCenter] = useState('')
  const [pageCount, setPageCount] = useState(1)

  // useEffect(() => {
  //   dispatch(filteredData(typeCenter));
  // }, [typeCenter, dispatch]);

  useEffect(() => {
    if (pageCount === 0) {
      setPageCount(1)
    }
  }, [pageCount])

  const handleIncrement = () => {
    if (pageCount >= 1) {
      setPageCount(pageCount + 1)
    }
  }

  const handleDecrement = () => {
    if (pageCount >= 1) {
      setPageCount(pageCount - 1)
    }
  }

  useEffect(() => {
    dispatch(
      fetchAllManifestShipments({
        page_size: 50,
        branch: userData?.branch?.name,
        order_type: 'LC',
        status: 1,
        in_manifest: 'False',
        page: pageCount,
        tpl: typeCenter
      })
    )
  }, [dispatch, pageCount, typeCenter])

  return (
    <div>
      <Modal
        isOpen={centerModal}
        onRequestClose={setCenterModal}
        style={styles}
        appElement={document.getElementById('root')}
      >
        <div
          className='w-9 h-9 hover:border-solid hover:border-[0.75px] hover:border-gray-500 ml-auto cursor-pointer'
          onClick={() => {
            if (typeCenter !== '') {
              setTypeCenter('')
            } else {
              setCenterModal(false)
              setTypeCenter('')
              setDestId('')
              setDisabled(true)
            }
          }}
        >
          <Times />
        </div>

        {typeCenter === 'AAJ' && (
          <>
            <div className='w-full text-xl flex items-center justify-center'>
              <div>Available shipments</div>
            </div>

            {shipmentsStore?.count !== null && shipmentsStore?.count > 1 && (
              <div className='w-full text-sm flex items-center justify-center mt-4 space-x-3'>
                <span className='cursor-pointer' onClick={handleDecrement}>
                  Previous
                </span>
                <span className='cursor-pointer' onClick={handleIncrement}>
                  Next
                </span>
              </div>
            )}

            {shipmentsStore?.data === undefined ? (
              <div className='flex flex-col justify-center items-center mt-4'>
                <CircularProgress size={24} color='inherit' />
              </div>
            ) : shipmentsStore?.data?.length === 0 ? (
              <div className='w-full flex justify-center items-center'>
                <div className='pt-14 w-full flex flex-col items-center justify-center'>
                  <img
                    src={noDataIcon}
                    className='w-20 h-20 object-contain'
                    alt='no data icon'
                  />
                  <p className='text-gray-500 text-left my-2 font-semibold text-sm'>
                    No shipment found
                  </p>
                </div>
              </div>
            ) : (
              <div className='w-full py-4 px-4 flex flex-col overflow-y-scroll'>
                {shipmentsStore?.data?.map((item, id) => (
                  <div className='flex space-x-4 p-1' key={id}>
                    <Check /> <div>{item?.tracking_id}</div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
        {typeCenter === 'RSE' && (
          <>
            <div className='w-full text-xl flex items-center justify-center py-2'>
              Avalaible shipments
            </div>

            {shipmentsStore?.data === undefined ? (
              <div className='flex flex-col justify-center items-center mt-4'>
                <CircularProgress size={24} color='inherit' />
              </div>
            ) : shipmentsStore?.data?.length === 0 ? (
              <div className='w-full flex justify-center items-center'>
                <div className='pt-14 w-full flex flex-col items-center justify-center'>
                  <img
                    src={noDataIcon}
                    className='w-20 h-20 object-contain'
                    alt='no data icon'
                  />
                  <p className='text-gray-500 text-left my-2 font-semibold text-sm'>
                    No shipment found
                  </p>
                </div>
              </div>
            ) : (
              <div className='w-full py-4 px-4 flex flex-col overflow-y-scroll'>
                {shipmentsStore?.data?.map((item, id) => (
                  <div className='flex space-x-4 p-1' key={id}>
                    <Check /> <div>{item?.tracking_id}</div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}

        {typeCenter === '' && (
          <div className='w-full flex justify-between items-center px-14'>
            <div
              className='text-white px-4 cursor-pointer w-[100px] h-[100px] border-[1px] border-primary rounded-full flex justify-center items-center font-semibold'
              onClick={() => setTypeCenter('AAJ')}
            >
              <img
                src={logo}
                className='w-70 h-70 object-contain'
                alt='no data icon'
              />
            </div>
            <div
              className='text-white px-4 cursor-pointer w-[100px] h-[100px] border-[1px] border-primary rounded-full flex justify-center items-center font-semibold'
              onClick={() => setTypeCenter('RSE')}
            >
              <img
                src={redstar}
                className='w-70 h-70 object-contain'
                alt='no data icon'
              />
            </div>
          </div>
        )}

        <div className='w-full flex justify-center'>
          {shipmentsStore?.data?.length !== 0 && (
            <>
              {typeCenter === 'AAJ' && (
                <select
                  name='period'
                  className='w-3/4 h-[40px] mt-4'
                  onChange={e => {
                    setDisabled(false)
                    setDestId(e.target.value)
                  }}
                >
                  <option className='bg-white' value={''}>
                    {' '}
                    Select destinations
                  </option>
                  {allHubs?.map((hub, id) => (
                    <option className='bg-white' value={hub?.id} key={id}>
                      {capitalizeFirstLetter(hub?.name)},{' '}
                      {hub?.category === 'TRANSIT_HUB'
                        ? 'Transit hub'
                        : 'Gateway hub'}
                    </option>
                  ))}
                </select>
              )}
            </>
          )}

          {shipmentsStore?.data?.length !== 0 && (
            <>
              {typeCenter === 'RSE' && (
                <select
                  name='period'
                  className='w-3/4 h-[40px] mt-4'
                  onChange={e => {
                    setDisabled(false)
                    setDestId(e.target.value)
                  }}
                >
                  <option className='bg-white' value={''}>
                    {' '}
                    Select destinations
                  </option>
                  {allHubs?.map((hub, id) => (
                    <option className='bg-white' value={hub?.id} key={id}>
                      {capitalizeFirstLetter(hub?.name)},{' '}
                      {hub?.category === 'TRANSIT_HUB'
                        ? 'Transit hub'
                        : 'Gateway hub'}
                    </option>
                  ))}
                </select>
              )}
            </>
          )}
        </div>

        <div className='w-full flex justify-end mt-12'>
          {typeCenter === 'AAJ' && (
            <button
              className={`w-[150px] h-[50px] btn ${
                disabled ? 'btn-neutral btn-disabled' : 'btn-primary'
              } btn-rounded`}
              disabled={disabled}
              onClick={() => {
                createManifest(typeCenter)
                setCenterModal(false)
                setTypeCenter('')
              }}
            >
              Proceed
            </button>
          )}

          {typeCenter === 'RSE' && (
            <button
              className={`w-[150px] h-[50px] btn ${
                disabled ? 'btn-neutral btn-disabled' : 'btn-primary'
              } btn-rounded`}
              disabled={disabled}
              onClick={() => {
                createManifest(typeCenter)
                setCenterModal(false)
                setTypeCenter('')
              }}
            >
              Proceed
            </button>
          )}
        </div>
      </Modal>
    </div>
  )
}
