import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import manifestApi from "../api/manifest";
import { parseError } from "../utils";

const initialState = {
  dataManifest: []
}

export const fetchManifest = createAsyncThunk(
  "manifest/fetch",
  async (params = {}) => {
    const query = Object.assign(
      {
        page_size: 50,
        page: 1,
      },
      params
    );
    const response = await manifestApi.getAllManifest(query);

    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        return {
          type: 'error',
          error: apiError
        }
      }
    }

    return response.data;
  }
);

const manifestSlice = createSlice({
  name: 'manifest',
  initialState,
  reducers: {
    // updateData(state, { payload }) {
    //   state.data = payload;
    // },
  },
  extraReducers: builder => {
    builder.addCase(fetchManifest.fulfilled, (state, action) => {
      state.dataManifest = action?.payload?.payload
    })
  }
})

// export const manifestActions = manifestSlice.actions

export default manifestSlice.reducer
