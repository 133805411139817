import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useContext
} from 'react'
import { Table } from '../../components'
import Pagination from '../../components/globals/pagination/ServerPagination'
import { useDispatch, useSelector } from 'react-redux'
import { fetchInvoices } from '../../slices/invoicesSlice'
import { fetchBranches } from '../../slices/orgsSlice'
import Page from '../../containers/Page'
import Search from '../../components/globals/Search/Search'
import { MdOutlineFileDownload } from 'react-icons/md'
import SearchResultsDescription from '../../components/globals/Search/SearchResultsDescription'
import InvoicesFilter from '../../components/invoices/InvoicesFilter'
import { resolveDateParams } from '../../helpers/queryByDate'
import { statuses } from '../../fixtures/invoiceStatus'
import FilterTag from '../../components/globals/filter/FilterTag'
import { AuthLayoutContext } from '../../containers/AuthLayout'
import invoicesApi from '../../api/invoice'
import {
  capitalizeFirstLetter,
  exportCSVData,
  getDate,
  initializeDateRange,
  parseError
} from '../../utils'
import useToast from '../../hooks/useToast'
import { CircularProgress } from '@mui/material'
import ExportDialogue from '../../components/globals/export/ExportDialogue'
import Filter from '../../components/globals/filter/Filter'
import Pill from '../../components/globals/Pill'
import ROUTES from '../../constants/routes'
import { useNavigate } from 'react-router-dom'

const searchOptions = [
  {
    name: 'Invoice ID',
    value: 'id'
  },
  {
    name: 'Order ID',
    value: 'order_id'
  },
  {
    name: 'Customer',
    value: 'customer_name'
  }
]

const Invoice = ({ metaTitle }) => {
  const { userRole } = useContext(AuthLayoutContext)

  const [serializedData, setSerializedData] = useState(null)
  const [searchBy, setSearchBy] = useState(searchOptions[0].value)
  const [searchValue, setSearchValue] = useState('')
  const [queryParams, setQueryParams] = useState({
    page: 1,
    page_size: 50
  })
  const [serverSearch, setServerSearch] = useState()
  const [filter, setFilter] = useState({
    status: '',
    date: '',
    branch: ''
  })
  const [filterTags, setFilterTags] = useState([])
  const [isExportLoading, setExportLoading] = useState(false)

  const userData = useSelector(state => state.auth.user)

  const [dateRange, setDateRange] = useState(initializeDateRange())
  const [isExportOpen, setExportOpen] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const toast = useToast()

  const invoicesStore = useSelector(state => state.invoices)

  const loadInvoices = useCallback(() => {
    dispatch(fetchInvoices(queryParams))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams])

  useEffect(() => {
    if (userRole?.domain.index > 0) {
      dispatch(fetchBranches())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRole?.domain.index])

  useEffect(() => {
    setSerializedData(null)
    loadInvoices()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams])

  const onSearchChange = ({ target }) => {
    setSearchValue(target.value)
  }

  useEffect(() => {
    const params = {}
    const tags = []

    for (const key in filter) {
      if (filter[key]) {
        if (key === 'date') {
          let tag = { name: key, value: '' }
          const { start_date, end_date } = resolveDateParams(
            filter.date,
            dateRange
          )
          params.start_date = start_date
          params.end_date = end_date
          if (filter.date === 'range') {
            tag.value = `From: ${dateRange[0]
              .format()
              .slice(0, 10)}, To: ${dateRange[1].format().slice(0, 10)}`
          } else {
            tag.value = capitalizeFirstLetter(filter[key].replaceAll('_', ' '))
          }
          tags.push(tag)
        } else if (key === 'status') {
          params.status = filter[key]
          let tag = { name: key, value: statuses[filter[key]].name }
          tags.push(tag)
        } else if (key === 'branch') {
          params.branch = filter[key]
          let tag = { name: key, value: filter[key] }
          tags.push(tag)
        }
      }
    }

    const query = { ...queryParams, ...params }
    query.page = 1

    setQueryParams(query)

    setFilterTags(tags)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, filter])

  const invoices = useMemo(() => {
    if (serializedData) {
      if (searchValue) {
        let results = []
        if (searchBy === 'id') {
          results = serializedData.filter(invoice =>
            String(invoice.id).includes(
              searchValue.toLowerCase().replace('#', '')
            )
          )
        } else if (searchBy === 'customer_name') {
          results = serializedData.filter(invoice =>
            invoice.customer_name
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          )
        } else if (searchBy === 'order_id') {
          results = serializedData.filter(invoice =>
            String(invoice.order).includes(
              searchValue.toLowerCase().replace('#', '')
            )
          )
        }

        return results
      }

      return serializedData
    } else return null
  }, [serializedData, searchBy, searchValue])

  const exportInvoices = async (params, onCompleted) => {
    delete params.page
    delete params.page_size

    const response = await invoicesApi.exportInvoices(params)
    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        toast('Error exporting data', 'error')
      }
      onCompleted()
      return
    }

    const file_name = (() => {
      // eslint-disable-next-line default-case
      switch (userRole?.domain.index) {
        case 0:
          return `invoices_${userData.branch.name}.csv`
        case 1: // update name for area
        case 2: // update name for region
        case 3:
          return 'invoices.csv'
      }
    })()

    exportCSVData(response.data, file_name)

    onCompleted()
  }

  const handleExport = () => {
    if (queryParams.start_date) {
      setExportLoading(true)

      exportInvoices(queryParams, () => {
        setExportLoading(false)
      })
    } else {
      setExportOpen(true)
    }
  }

  const closeExport = () => {
    setExportOpen(false)
  }

  const searchInputPlaceHolder = useMemo(() => {
    switch (searchBy) {
      case 'order_id':
        return 'Enter order ID'
      case 'customer_name':
        return 'Enter customer name'
      case 'id':
        return 'Enter invoice ID'
      default:
        break
    }
  }, [searchBy])

  const onPage = params => {
    setSerializedData(null)
    setQueryParams(state => ({ ...state, ...params }))
  }

  const handleServerSearch = () => {
    setSerializedData(null)
    setQueryParams(state => ({ ...state, [searchBy]: searchValue }))
    setServerSearch({
      searchBy: searchOptions.find(opt => opt.value === searchBy)?.name,
      searchValue
    })
    setSearchValue('')
  }

  const onCloseServerSearch = () => {
    setServerSearch(null)
    const query = { ...queryParams }
    delete query[searchBy]
    setQueryParams(query)
  }

  const onFilterDelete = key => {
    setFilter(state => ({
      ...state,
      [key]: ''
    }))
    let query = { ...queryParams }
    if (key === 'date') {
      delete query.start_date
      if (filter[key] !== 'today') delete query.end_date
      if (filter[key] === 'range') setDateRange(initializeDateRange())
    } else delete query[key]

    setQueryParams(query)
  }

  const tableHeader = [
    'S/N',
    'ID',
    'Order',
    'Date Raised',
    'Carrier',
    'Weight',
    'Billed To',
    'Total Amount',
    'Branch',
    'Status'
  ]

  const tableData = useMemo(() => {
    return invoices?.map(invoice => ({
      ...invoice,
      'S/N': invoice.s_n,
      ID: `#${invoice.id}`,
      Order: `#${invoice.order}`,
      'Date Raised': getDate(invoice.date_raised),
      Carrier:
        invoice.meta.tpl_service === 'FIE'
          ? 'Economy by FEDEX'
          : invoice.meta.tpl_service === 'FPE'
          ? 'Priority by FEDEX'
          : invoice.meta.tpl_service,
      Weight: `${invoice.weight} kg`,
      'Billed To': invoice.customer_name,
      'Total Amount': invoice.total?.toLocaleString('en-NG', {
        style: 'currency',
        currency: 'NGN'
      }),
      Branch: invoice.branch_name,
      Status: invoice.status !== 2 && (
        <Pill
          name={statuses[invoice.status]?.name}
          theme={statuses[invoice.status]?.theme}
        />
      )
    }))
  }, [invoices])

  const handleInvoiceClick = invoice => {
    navigate(`${ROUTES.INVOICES.path}${invoice.id}`)
  }
  return (
    <Page metaTitle={metaTitle}>
      <Page.Header title={'Invoices'}>
        <Search
          value={searchValue}
          multiple={true}
          searchBy={searchBy}
          searchOptions={searchOptions}
          onSearchOptionChange={option => setSearchBy(option)}
          inputPlaceHolder={searchInputPlaceHolder}
          handleSearch={onSearchChange}
          allowServerSearch={true}
          onServerSearch={handleServerSearch}
        />
        <Filter
          Component={InvoicesFilter}
          filter={filter}
          setFilter={setFilter}
          dateRange={dateRange}
          setDateRange={setDateRange}
        />
      </Page.Header>
      <Page.Body>
        {isExportOpen && (
          <ExportDialogue
            isOpen={isExportOpen}
            name='invoices'
            onClose={closeExport}
            options={queryParams}
            onExport={exportInvoices}
          />
        )}

        <div className='mb-3 flex flex-col gap-2 lg:gap-3'>
          <div className='flex items-center gap-2 lg:gap-3'>
            {serverSearch && (
              <SearchResultsDescription
                searchState={serverSearch}
                onClose={onCloseServerSearch}
              />
            )}
            {!!filterTags.length && (
              <div className='flex items-center gap-2 flex-wrap'>
                <p className='text-base font-medium text-dark-primary'>
                  Filter:
                </p>
                {filterTags.map(({ name, value }, id) => (
                  <FilterTag
                    key={id}
                    name={name}
                    value={value}
                    onDelete={onFilterDelete}
                  />
                ))}
              </div>
            )}
            <div className='flex lg:hidden ml-auto'>
              <Pagination
                tableId='invoices-table'
                totalCount={invoicesStore.meta?.count}
                data={invoicesStore.data}
                setSerializedData={setSerializedData}
                onPage={onPage}
                page={invoicesStore.meta?.page}
                pageSize={invoicesStore.meta?.page_size}
                allowServerSearch={true}
                onServerSearch={handleServerSearch}
              />
            </div>
          </div>

          <div className='flex gap-2 flex-row flex-wrap items-center justify-between w-full'>
            <div className='flex items-center gap-3 ml-auto'>
              <div className='hidden lg:flex'>
                <Pagination
                  tableId='invoices-table'
                  totalCount={invoicesStore.meta?.count}
                  data={invoicesStore.data}
                  setSerializedData={setSerializedData}
                  onPage={onPage}
                  page={invoicesStore.meta?.page}
                  pageSize={invoicesStore.meta?.page_size}
                  allowServerSearch={true}
                  onServerSearch={handleServerSearch}
                />
              </div>
              <button
                className='btn bg-g-400 text-dark-primary'
                onClick={handleExport}
                disabled={!invoices?.length}
              >
                <MdOutlineFileDownload
                  size={18}
                  color='#333333'
                  className='mr-1'
                />
                {isExportLoading ? (
                  <>
                    Exporting
                    <CircularProgress
                      style={{ marginLeft: '0.5rem' }}
                      size={18}
                      color='inherit'
                    />
                  </>
                ) : (
                  'Export'
                )}
              </button>
            </div>
          </div>
        </div>

        <Table
          id='invoices-table'
          headers={tableHeader}
          data={tableData}
          rowAction={handleInvoiceClick}
          emptyDataText='No invoice found'
        />
      </Page.Body>
    </Page>
  )
}

export default Invoice
