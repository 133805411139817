import { IoIosArrowForward } from 'react-icons/io'
import { Link } from 'react-router-dom'

export default function BreadCrumb ({ links }) {
  return (
    <div>
      <div className='flex gap-2 text-xs'>
        {links.map(link => (
          <div>
            {link.disabled ? (
              <div className='flex items-center gap-1'>
                <IoIosArrowForward />
                <span className='select-none'>{link.name}</span>
              </div>
            ) : (
              <Link
                to={link.path}
                className='hover:text-primary hover:underline flex items-center gap-1'
              >
                <IoIosArrowForward />
                {link.name}
              </Link>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
