import React, { useState } from 'react'
import Page from '../../containers/Page'
import TrackingDetails from '../../components/tracking/TrackingDetails'
import { useSearchParams } from 'react-router-dom'
import manifestApi from '../../api/manifest'
import { Loader } from '../../components/globals'
import TrackingError from '../../components/tracking/TrackingError'
import { parseError } from '../../utils'
import shipmentsApi from '../../api/shipments'

export default function Track ({ metaTitle }) {
  const [searchParams] = useSearchParams()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [state, setState] = useState('input-tracking')
  const [trackingID, setTrackingID] = useState(searchParams.get('track_id'))
  const [manifestID, setManifestID] = useState('')
  const [trackingDetails, setTrackDetails] = useState()
  const [inputState, setInputState] = useState('tracking')

  const handleSubmit = async e => {
    e.preventDefault()
    error && setError('')

    setLoading(true)
    if (inputState === 'tracking') {
      const response = await shipmentsApi.trackShipmentWithTrackingID(
        trackingID.replace('#', '')
      )

      if (!response.ok) {
        const apiError = parseError(response)
        if (apiError) {
          setError(apiError.data.detail.message)
        }
        setLoading(false)
        return
      }

      setLoading(false)
      setTrackDetails(response.data)
      setState('tracking-details')
    } else {
      const response = await manifestApi.bulkTracking(manifestID)
      if (!response.ok) {
        const apiError = parseError(response)
        if (apiError) {
          setError(apiError.data.detail.message)
        }
        setLoading(false)
        return
      }
    }
  }

  const handleInputChange = ({ target }) => {
    if (inputState === 'tracking') {
      setTrackingID(target.value)
    } else {
      setManifestID(target.value)
    }
  }

  const closeShipmentStatus = () => {
    setState('input-tracking')
    setTrackDetails(undefined)
    setTrackingID('')
  }

  return (
    <Page metaTitle={metaTitle} className='tracking-modal__background'>
      <Page.Header title={'Tracking'} transparent={false}></Page.Header>
      <Page.Body>
        <div className='w-full max-w-[750px] overflow-y-auto text-dark-primary p-4 pb-12 rounded-[4px]'>
          {state === 'input-tracking' ? (
            <>
              <h4 className='mb-3 text-2xl font-bold'>
                Let's Track Your Shipment
              </h4>
              <p className='text-sm mb-4 font-light'>
                Find and track packages effortlessly
              </p>
              <form
                id='trackingID'
                className='bg-white p-2 rounded-[30px] text-[17px] w-full max-w-[500px] flex items-center justify-between'
                onSubmit={handleSubmit}
              >
                <select
                  className='border-none h-full focus:border-0 focus:shadow-none bg-white text-sm'
                  onChange={e => setInputState(e.target.value)}
                >
                  <option className='bg-white' value={'tracking'}>
                    Tracking
                  </option>
                  <option className='bg-white' value={'manifest'}>
                    Manifest
                  </option>
                </select>
                <input
                  placeholder={
                    inputState === 'tracking'
                      ? 'Input tracking ID'
                      : 'Input manifest ID'
                  }
                  className='border-none outline-0 w-full placeholder:text-dark-primary/40'
                  required
                  defaultValue={trackingID}
                  onChange={handleInputChange}
                />
                <button
                  className='btn btn-primary rounded-[26px]'
                  form='trackingID'
                  type='submit'
                  disabled={loading}
                >
                  <span className='px-2'>Track</span>
                </button>
              </form>
            </>
          ) : (
            <TrackingDetails
              trackingDetails={trackingDetails}
              trackingID={trackingID}
              onClose={closeShipmentStatus}
            />
          )}
          {loading && (
            <div className='pt-8'>
              <Loader page={false} />
            </div>
          )}
          {error && (
            <TrackingError onClose={() => setError('')} message={error} />
          )}
        </div>
      </Page.Body>
    </Page>
  )
}
