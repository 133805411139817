import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { ReactComponent as Times } from '../../assets/times.svg'
import { Formik } from 'formik'
import AppForm from '../globals/Form/AppForm'
import AppFormField from '../globals/Form/AppFormField'
import AppFormSelectField from '../globals/Form/AppFormSelectField'
import * as yup from 'yup'
import { CircularProgress } from '@mui/material'
import salesforceApi from '../../api/salesforce'
import { parseError } from '../../utils'
import useToast from '../../hooks/useToast'
import { useDispatch, useSelector } from 'react-redux'
import { fetchBanks } from '../../slices/banksSlice'
import { BsInfoCircle } from 'react-icons/bs'
import CreateAgentSuccessful from './CreateAgentSuccessful'

const style = {
  content: {
    inset: '16px',
    maxWidth: '750px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%',
    overflow: 'auto',
    borderRadius: '20px'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function CreateSalesforceAgent ({ isOpen, onClose }) {
  const toast = useToast()
  const dispatch = useDispatch()

  const banks = useSelector(state => state.banks)

  const [isSuccess, setSuccess] = useState(false)
  const [agent, setAgent] = useState({})

  useEffect(() => {
    dispatch(fetchBanks())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const initialValues = {
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    bank_details: { account_number: '', bank: '', account_name: '' },
    commission: 10
  }

  const validationSchema = props =>
    yup.lazy(values =>
      yup.object().shape({
        first_name: yup
          .string()
          .min(2, 'Too short')
          .max(50, 'Too long')
          .required('First name is required'),
        last_name: yup
          .string()
          .min(2, 'Too short')
          .max(50, 'Too long')
          .required('Last name is required'),
        phone: yup
          .string()
          .min(6, 'Minimum of 6 numbers')
          .max(15, 'Maximum of 15 numbers')
          .required('Phone number is required'),
        email: yup
          .string()
          .email('Must be a valid email')
          .required('Email is required'),
        bank_details: yup.object().shape({
          account_number: yup
            .string()
            .required('Account number is required')
            .min(10, 'Minimum of 10 numbers')
            .max(10, 'Maximum of 10 numbers'),
          bank: yup.string().required('Bank is required'),
          account_name: yup.string().required('Account name is required')
        }),
        commission: yup
          .number()
          .max(100, 'Cannot be more than 100%')
          .min(0, 'Cannot be less than 0%')
          .required('Commission is required')
      })
    )

  const handleSubmit = async (body, actions) => {
    const payload = {
      ...body,
      phone: `+234${body.phone}`,
      bank_details: {
        ...body.bank_details,
        account_number: body.bank_details.account_number.toString()
      },
      is_verified: true
    }

    const response = await salesforceApi.createSalesforceAgent(payload)

    actions.setSubmitting(false)
    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        toast('Error creating salesforce agent', 'error')
      }
      return
    }

    setAgent({
      name: `${body.first_name} ${body.last_name}`,
      email: body.email
    })
    setSuccess(true)
  }

  const handleSuccessClose = () => {
    setSuccess(false)
    onClose(true)
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={style}
      appElement={document.getElementById('root')}
    >
      <div>
        <div
          className='w-9 h-9 hover:border-solid hover:border-[0.75px] hover:border-gray-500 ml-auto cursor-pointer'
          onClick={onClose}
        >
          <Times />
        </div>
        <div className='flex flex-col p-4 gap-6'>
          <h5 className='text-[#3A3A3A] text-xl text-center font-bold'>
            Create New SalesForce Agent
          </h5>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit, isSubmitting }) => (
              <div className='px-4'>
                <AppForm onSubmit={handleSubmit} id='new_agent_form'>
                  <div className='bg-g-200/50 p-6 grid grid-cols-2 gap-3'>
                    <AppFormField
                      name='first_name'
                      title='First Name'
                      placeholder='e.g Noah'
                    />
                    <AppFormField
                      name='last_name'
                      title='Last Name'
                      placeholder='e.g Ayodele'
                    />
                    <AppFormField
                      name='email'
                      type='email'
                      title='Email'
                      placeholder='e.g noahayodele@example.com'
                    />
                    <AppFormField
                      name='phone'
                      title='Phone Number'
                      placeholder='8123456789'
                      type='number'
                      phonecode='+234'
                    />
                    <AppFormField
                      name='bank_details.account_number'
                      type='number'
                      title='Bank Account Number'
                      placeholder='0012385424'
                    />
                    <AppFormSelectField
                      name='bank_details.bank'
                      title='Bank Name'
                    >
                      <option value=''>Select bank</option>
                      {banks.data?.map(bank => (
                        <option
                          value={bank.name}
                          key={bank.code}
                        >{`${bank.name} (${bank.code})`}</option>
                      ))}
                    </AppFormSelectField>
                    <div>
                      <AppFormField
                        name='bank_details.account_name'
                        title='Account Name'
                        placeholder=''
                      />
                      <div className='flex gap-1 w-full mt-1'>
                        <span className='w-4 block'>
                          <BsInfoCircle
                            size={14}
                            className='mt-0.5'
                            color='#33333399'
                          />
                        </span>
                        <p className='text-xs text-dark-primary/60 w-[calc(100%_-_1.25rem)]'>
                          Account name should correspond with Agent's name.
                        </p>
                      </div>
                    </div>
                    <AppFormField
                      name='commission'
                      type='number'
                      title='Commission (%)'
                      placeholder='10'
                    />
                  </div>
                  <div className='flex w-full justify-end'>
                    <button
                      className='btn btn-primary'
                      type='submit'
                      form='new_agent_form'
                    >
                      {isSubmitting ? (
                        <CircularProgress size={28} color='inherit' />
                      ) : (
                        'Create'
                      )}
                    </button>
                  </div>
                </AppForm>
              </div>
            )}
          </Formik>
        </div>
      </div>
      {isSuccess && (
        <CreateAgentSuccessful
          isOpen={isSuccess}
          onClose={handleSuccessClose}
          agent={agent}
        />
      )}
    </Modal>
  )
}
