import Modal from 'react-modal'
import { ReactComponent as Times } from '../../assets/times.svg'
import { getDate, getStateOfCountry, parseError } from '../../utils'
import { useContext, useEffect, useMemo, useState } from 'react'
import shipmentsApi from '../../api/shipments'
import useToast from '../../hooks/useToast'
import { CircularProgress } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import ROUTES from '../../constants/routes'
import useApi from '../../hooks/useApi'
import { Loader } from '../globals'
import ShipmentStatusUpdate from './ShipmentStatusUpdate'
import { Upload } from '../globals/Upload'
import { AuthLayoutContext } from '../../containers/AuthLayout'

const style = {
  content: {
    inset: '16px',
    maxWidth: '750px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%',
    overflow: 'auto',
    borderRadius: '0'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function ShipmentDetail ({
  id,
  isOpen,
  onClose,
  onVoided,
  onUpdateShipments,
  type
}) {
  const { data, request: readShipment } = useApi(shipmentsApi.readShipment, id)
  const { userRole } = useContext(AuthLayoutContext)

  const [isVoiding, setVoiding] = useState(false)
  const [uploadModal, setUploadModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const toast = useToast()
  const navigate = useNavigate()

  const shipment = useMemo(() => {
    if (data?.payload) {
      return data.payload
    }
  }, [data])

  useEffect(() => {
    readShipment()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTrackShipment = () => {
    navigate(`${ROUTES.TRACK.path}?track_id=${shipment.tracking_id}`)
  }

  const handleVoidOrder = async () => {
    setVoiding(true)

    const isUnrestricted =
      userRole.id === 1 || userRole.id === 15 || userRole.id === 17

    const response = isUnrestricted
      ? await shipmentsApi.voidShipmentUnrestricted(id)
      : await shipmentsApi.voidShipment(id)

    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        toast('Unable to void this shipment', 'error')
      }
      setVoiding(false)
      return
    }

    toast('Successfully voided order')
    setVoiding(false)
    onVoided()
  }

  const updateShipment = () => {
    readShipment()
    onUpdateShipments()
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={style}
      appElement={document.getElementById('root')}
    >
      {!shipment ? (
        <Loader />
      ) : (
        <div>
          <div
            className='w-9 h-9 hover:border-solid hover:border-[0.75px] hover:border-gray-500 ml-auto cursor-pointer'
            onClick={onClose}
            role='button'
          >
            <Times />
          </div>
          <div className='flex flex-col p-4 gap-6'>
            <div className='flex flex-row justify-between items-start w-full'>
              <div>
                <h5 className='text-[#3A3A3A] text-xl font-bold'>
                  {shipment.tracking_id}
                </h5>
                {shipment.shipment_tracking_number && (
                  <p className='text-g-600 text-sm'>
                    Carrier Track No.: {shipment.shipment_tracking_number}
                  </p>
                )}
              </div>
              <div className='flex items-center gap-2'>
                <button
                  className='btn btn-sm btn-primary w-16'
                  onClick={handleTrackShipment}
                >
                  Track
                </button>
                {!(shipment.void_state || shipment.status === 7) && (
                  <button
                    className='btn btn-sm btn-secondary w-16'
                    onClick={handleVoidOrder}
                  >
                    {isVoiding ? (
                      <CircularProgress size={16} color='inherit' />
                    ) : (
                      'Void'
                    )}
                  </button>
                )}
              </div>
            </div>
            <div className='flex flex-col gap-6 text-dark-primary/70'>
              <div className='flex flex-row items-start justify-between'>
                <div className='w-1/2 text-left opacity-80'>
                  <p>Status</p>
                </div>
                <div className='font-medium w-1/2 flex justify-end'>
                  <ShipmentStatusUpdate
                    trackingId={shipment.tracking_id}
                    statusId={shipment.status}
                    onSuccess={updateShipment}
                    deliveryMode={
                      shipment.meta.delivery_type || shipment.meta.delivery_mode
                    }
                  />
                </div>
              </div>

              <div className='flex flex-row items-start justify-between'>
                <div className='w-1/2 text-left opacity-80'>
                  <p>Order ID</p>
                </div>
                <div className='font-medium w-1/2 text-right'>
                  <p>
                    <Link
                      to={`${ROUTES.ORDERS.path}?id=${shipment.order}`}
                      className='hover:underline hover:text-primary'
                    >
                      #{shipment.order}
                    </Link>
                  </p>
                </div>
              </div>
              <div className='flex flex-row items-start justify-between'>
                <div className='w-1/2 text-left opacity-80'>
                  <p>Receiver</p>
                </div>
                <div className='font-medium w-1/2 text-right'>
                  <p>{shipment.meta.receiver_name}</p>
                </div>
              </div>
              <div className='flex flex-row items-start justify-between'>
                <div className='w-1/2 text-left opacity-80'>
                  <p>Date & Time</p>
                </div>
                <div className='font-medium w-1/2 text-right'>
                  <p>{getDate(shipment.created_at)}</p>
                </div>
              </div>
              <div className='flex flex-row items-start justify-between'>
                <div className='w-1/2 text-left opacity-80'>
                  <p>Origin</p>
                </div>
                <div className='font-medium w-1/2 text-right'>
                  <p>
                    {getStateOfCountry(
                      shipment.meta.origin[1],
                      shipment.meta.origin[0]
                    )?.name || shipment.meta.destination[1]}{' '}
                    {shipment.meta.origin[0]}
                  </p>
                </div>
              </div>
              <div className='flex flex-row items-start justify-between'>
                <div className='w-1/2 text-left opacity-80'>
                  <p>Destination</p>
                </div>
                <div className='font-medium w-1/2 text-right'>
                  <p>
                    {getStateOfCountry(
                      shipment.meta.destination[1],
                      shipment.meta.destination[0]
                    )?.name || shipment.meta.destination[1]}{' '}
                    {shipment.meta.destination[0]}
                  </p>
                </div>
              </div>
              <div className='flex flex-row items-start justify-between'>
                <div className='w-1/2 text-left opacity-80'>
                  <p>
                    {!shipment.meta.tpl_service.includes('AAJ')
                      ? 'Delivery Type'
                      : 'Delivery Mode'}
                  </p>
                </div>
                <div className='font-medium w-1/2 text-right'>
                  <p>
                    {!shipment.meta.tpl_service?.includes('AAJ')
                      ? shipment.meta.delivery_type?.replace('_', ' ')
                      : shipment.meta.delivery_mode?.replace('_', ' ')}
                  </p>
                </div>
              </div>
              {/* {shipment.meta.delivery_mode === 'PICKUP' && (
              <div className='flex flex-row items-start justify-between'>
                <div className='w-1/2 text-left opacity-80'>
                  <p>Pickup Hub</p>
                </div>
                <div className='font-medium w-1/2 text-right'>
                  <p className='capitalize'>
                    {shipment.order_shipment.pickup_hub.location} -{' '}
                    {shipment.order_shipment.pickup_hub.name}
                  </p>
                </div>
              </div>
            )} */}
              <div className='flex flex-row items-start justify-between'>
                <div className='w-1/2 text-left opacity-80'>
                  <p>Carrier</p>
                </div>
                <div className='font-medium w-1/2 text-right'>
                  <p>
                    {shipment.meta.tpl_service === 'FIE' ||
                    shipment.meta.tpl_service === 'FPE'
                      ? 'FEDEX'
                      : shipment.meta.tpl_service}
                  </p>
                </div>
              </div>

              <div className='flex flex-row items-start justify-between'>
                <div className='w-1/2 text-left opacity-80'>
                  <p>Current Tracking Update</p>
                </div>
                <div className='font-medium w-1/2 text-right'>
                  <p>{shipment.current_tracking_updates}</p>
                </div>
              </div>

              {type === 'LC' && shipment.acknowledgement_copy === null && (
                <>
                  <div className='flex flex-row items-start justify-between'>
                    <div className='w-1/2 text-left opacity-80'>
                      <p>Acknowledge document required</p>
                    </div>
                    <div className='font-medium w-1/2 text-right cursor-pointer'>
                      <button
                        className='btn btn-sm btn-primary w-40'
                        onClick={() => setUploadModal(true)}
                      >
                        Upload document{' '}
                        {loading && (
                          <CircularProgress size={13} color='inherit' />
                        )}
                      </button>
                    </div>
                  </div>
                </>
              )}

              {/* {shipment.status === 5 && (
                <>
                  <div className='flex flex-row items-start justify-between'>
                    <div className='w-1/2 text-left opacity-80'>
                      <p>Exception Code</p>
                    </div>
                    <div className='font-medium w-1/2 text-right'>
                      <p>{shipment.exception_code}</p>
                    </div>
                  </div>
                  <div className='flex flex-row items-start justify-between'>
                    <div className='w-1/2 text-left opacity-80'>
                      <p>Date & Time Raised</p>
                    </div>
                    <div className='font-medium w-1/2 text-right'>
                      <p>23/10/2023 2:00PM</p>
                    </div>
                  </div>
                  <div className='flex flex-row items-start justify-between'>
                    <div className='w-1/2 text-left opacity-80'>
                      <p>Exception Status</p>
                    </div>
                    <div className='font-medium w-1/2 text-right'>
                      <p>Unresolved</p>
                    </div>
                  </div>
                </>
              )} */}
            </div>
          </div>
          <Upload
            trackingId={shipment.tracking_id}
            setUploadModal={setUploadModal}
            uploadModal={uploadModal}
            setLoading={setLoading}
          />
        </div>
      )}
    </Modal>
  )
}
