import { useEffect, useMemo, useState } from 'react'
import FilterButton from '../../components/globals/filter/FilterButton'
import Page from '../../containers/Page'
import TotalCard from '../../components/dashboard/TotalCard'
import ShipmentsBarChart from '../../components/dashboard/ShipmentsBarChart'
import ShipmentTypePieChart from '../../components/dashboard/ShipmentTypePieChart'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDashboard } from '../../slices/dashboardSlice'
import { Loader } from '../../components/globals'
import { fetchOrders } from '../../slices/ordersSlice'
import DashboardFilter from '../../components/dashboard/DashboardFilter'
import { fetchBranches } from '../../slices/orgsSlice'
import CeoBookingCard from '../../components/dashboard/CeoBookingCard'
import RecentOrders from '../../components/dashboard/RecentOrders'
import { MdOutlineFileDownload } from 'react-icons/md'
import { getDate, getStateOfCountry, initializeDateRange } from '../../utils'
import * as XLSX from 'xlsx'

const convertToKeyValueArray = obj => {
  if (obj) {
    return Object.entries(obj)?.map(([key, value]) => ({
      title: key,
      value: value
    }))
  }
}
function AreaDashbaord ({ metaTitle }) {
  const dashboardData = useSelector(state => state.dashboard)
  const dashboardOrdersData = useSelector(state => state.orders)
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [expandedCardIndex, setExpandedCardIndex] = useState(null)

  const [dateRange, setDateRange] = useState(initializeDateRange())

  const handleToggle = index => {
    setExpandedCardIndex(index === expandedCardIndex ? null : index)
  }

  const dispatch = useDispatch()

  const getQueryParams = filter => {
    const query = {}

    for (let key in filter) {
      if (key === 'start_month' || key === 'end_month') {
        if (filter[key]) {
          query[key] = filter[key].value
        }
      } else if (filter[key]) {
        query[key] = filter[key]
      }
    }

    return new URLSearchParams(query).toString()
  }
  useEffect(() => {
    dispatch(fetchOrders())
    dispatch(fetchBranches())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const formatType = item => {
    switch (item) {
      case 'Local':
        return 'LC'
      case 'International':
        return `IN`
      default:
        return ''
    }
  }

  const formatTpl = item => {
    switch (item) {
      case 'FedEx':
        return 'FIE'
      case 'RedStar Express':
        return 'RSE'
      default:
        return item
    }
  }

  const formatPayment = item => {
    switch (item) {
      case 'Transfer':
        return 'TRF'
      case 'Cash':
        return 'CASH'
      case 'POS':
        return 'POS'
      case 'Pay later':
        return 'PAY_LATER'
      default:
        break
    }
  }

  useEffect(() => {
    if (dashboardData.filter) {
      const cardFilterParams = {
        start_date: dashboardData.filter.start_date,
        end_date:
          dashboardData.filter.date === 'today'
            ? dashboardData.filter.start_date
            : dashboardData.filter.end_date,
        branch_name: dashboardData.filter.branch_name,
        shipmentType: formatType(dashboardData.filter.shipmentType),
        payment_method: formatPayment(dashboardData.filter.payment),
        tpl_service: formatTpl(dashboardData?.filter.tpl)
      }
      dispatch(fetchDashboard(`?${getQueryParams(cardFilterParams)}`))
    } else {
      dispatch(fetchDashboard())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardData.filter])

  const showFilter = () => {
    setIsFilterOpen(true)
  }

  useEffect(() => {
    if (dashboardData.data) {
      setLoading(false)
    }
  }, [dashboardData.data])

  const CeoCardDetails = useMemo(() => {
    if (dashboardData.data) {
      const cardData = dashboardData.data
      return [
        {
          title: 'Customer Bookings',
          amount:
            cardData?.customer_bookings_data?.total_customer_bookings?.toLocaleString(
              'en-NG',
              {
                style: 'currency',
                currency: 'NGN'
              }
            ),
          details: convertToKeyValueArray(
            cardData?.customer_bookings_data?.branch_booking
          )
        },
        {
          title: 'Partners Bookings',
          amount:
            cardData?.partner_bookings_data?.total_partner_bookings?.toLocaleString(
              'en-NG',
              {
                style: 'currency',
                currency: 'NGN'
              }
            ),
          details: convertToKeyValueArray(
            cardData?.partner_bookings_data?.branch_booking
          )
        },
        {
          title: 'Shipments Status',
          details: [
            {
              title: 'Pending',
              value: cardData?.shipments ? cardData?.shipments?.pending : '--'
            },
            {
              title: 'In transit',
              value: cardData?.shipments
                ? cardData?.shipments['in-transit']
                : '--'
            },
            {
              title: 'Voided',
              value: cardData?.shipments?.voided
            },
            {
              title: 'Exception',
              value: cardData?.shipments?.exception
            },
            {
              title: 'Out for delivery',
              value: cardData?.shipments
                ? cardData?.shipments['available-for-pickup-by-customer']
                : '--'
            },
            {
              title: 'Ready for pickup',
              value: cardData?.shipment
                ? cardData?.shipments['available-for-pickup-by-customer']
                : '--'
            },
            {
              title: 'Delivered',
              value: cardData?.shipments?.delivered
            }
          ]
        }
        // {
        //   title: 'Average Delivery Time',
        //   details: [
        //     {
        //       text: 'Abeokuta',
        //       value: 'N 15'
        //     }
        //   ]
        // }
      ]
    }
  }, [dashboardData.data])

  const totals = useMemo(() => {
    if (dashboardData.data) {
      return [
        {
          title: 'Total Revenue Generated',
          amount: (
            dashboardData?.data?.customer_bookings_data
              ?.total_customer_bookings +
            dashboardData?.data?.partner_bookings_data?.total_partner_bookings
          ).toLocaleString('en-NG', {
            style: 'currency',
            currency: 'NGN'
          })
        },
        {
          title: 'Total Shipments Created',
          amount: dashboardData?.data?.shipments?.total_shipments
        },
        {
          title: 'Total Number Of Customers',
          amount: dashboardData?.data?.total_customers
        },
        {
          title: 'Total Number Of Partners',
          amount: dashboardData?.data?.total_partners
        }
        // {
        //   title: 'Average Delivery Time',
        //   amount: dashboardData.data.total_partners
        // }
      ]
    }
  }, [dashboardData.data])

  const shipmentsTypes = useMemo(() => {
    if (dashboardData.data) {
      return {
        LC: dashboardData.data.shipments?.local_shipments,
        IN: dashboardData.data.shipments?.international_shipments
      }
    }
  }, [dashboardData.data])

  const orderStat = useMemo(() => {
    if (dashboardData.data) {
      return dashboardData.data.order_stat
    }
  }, [dashboardData.data])

  const shipmentsChartData = useMemo(() => {
    if (dashboardData.data) {
      return {
        total_shipments: dashboardData?.data?.shipments?.total_shipments,
        monthly_shipments: dashboardData?.data?.shipments?.monthly_shipments
      }
    }
  }, [dashboardData.data])

  const isLoading = useMemo(() => {
    return (
      loading || !totals || !shipmentsTypes || !orderStat || !shipmentsChartData
    )
  }, [loading, orderStat, shipmentsChartData, shipmentsTypes, totals])

  const onApplyFilter = () => setLoading(true)

  const closeFilter = () => setIsFilterOpen(false)

  const onFilterDelete = () => setLoading(true)

  const tableHeader = [
    'ID',
    'Date',
    'Name',
    'Contact',
    'Origin',
    'Destination',
    'Status'
  ]

  const orders = useMemo(() => {
    if (dashboardOrdersData.data) return dashboardOrdersData.data
    return []
  }, [dashboardOrdersData.data])

  const exportInvoices = () => {
    const createHeader = () => {
      const header = tableHeader.filter(item => item !== 'S/N')
      return header
    }

    // create rows
    const createRows = () => {
      const rows = orders.map((item, ind) => {
        return {
          ID: item.id,
          Date: getDate(item.order_date),
          Name: item.sender.contact?.name,
          Contact: item.sender.contact?.phone_number || '--',
          Origin:
            getStateOfCountry(
              item.sender.address?.state_or_province_code,
              item.sender.address?.country
            )?.name ||
            `${item.sender.address?.state_or_province_code}
        , ${item.sender.address?.country}`,
          Destination:
            getStateOfCountry(
              item.receiver.address?.state_or_province_code,
              item.receiver.address?.country
            )?.name ||
            `${item.receiver.address?.state_or_province_code}
          , ${item.receiver.address?.country}`,
          Status: item.paid
        }
      })

      return rows
    }

    const intl_header = createHeader('orders')
    const intl_rows = createRows('orders')

    // generate workbook
    const workbook = XLSX.utils.book_new()

    // generate worksheets
    const intl_sheet = XLSX.utils.json_to_sheet(intl_rows)

    XLSX.utils.book_append_sheet(workbook, intl_sheet, 'Recent Orders')

    // fix headers
    XLSX.utils.sheet_add_aoa(intl_sheet, [intl_header], {
      origin: 'A1'
    })

    // column width
    const max_width = intl_rows.reduce((w, r) => Math.max(w, r.Sender?.length))

    intl_sheet['!cols'] = [{ wch: max_width }]

    XLSX.writeFile(workbook, `Recent_Orders.xlsx`, {
      compression: true
    })
  }
  return (
    <>
      <Page metaTitle={metaTitle} noPadding>
        <Page.Header title={'Overview'}>
          <FilterButton onClick={showFilter} />
        </Page.Header>
        <Page.Body>
          <div className='flex w-full justify-end align-center mt-10 mr-[17px] mb-5'>
            <button
              onClick={exportInvoices}
              className='btn bg-[#E8E8E8] border-[1.5px]  bg-transparent text-dark-primary mr-7'
            >
              <MdOutlineFileDownload
                size={18}
                color='#333333'
                className='mr-1'
              />
              Export
            </button>
          </div>
          {isFilterOpen && (
            <DashboardFilter
              isOpen={isFilterOpen}
              onClose={closeFilter}
              onApplyFilter={onApplyFilter}
              isBranchSpecific={false}
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
          )}
          <DashboardFilter.Description onDelete={onFilterDelete} />
          {isLoading ? (
            <Loader />
          ) : (
            <div>
              <div className='flex flex-col gap-4'>
                <div className='flex flex-wrap'>
                  {totals.map((total, id) => (
                    <div
                      className='w-full sm:w-1/2 md:w-1/2 lg:w-1/4 p-2'
                      key={id}
                    >
                      <TotalCard title={total.title} total={total.amount} />
                    </div>
                  ))}
                </div>

                <div className='flex flex-col gap-4'>
                  <div className='flex flex-wrap -m-2'>
                    {CeoCardDetails.map((cardDetails, ind) => (
                      <div
                        className='p-2 w-full sm:w-1/0 md:w-1/3 lg:w-1/3'
                        key={ind}
                      >
                        <CeoBookingCard
                          title={cardDetails.title}
                          amount={cardDetails.amount}
                          details={cardDetails.details}
                          isExpanded={expandedCardIndex === ind}
                          onToggle={() => handleToggle(ind)}
                          dateRange={dateRange}
                          setDateRange={setDateRange}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <h1 className='text-2xl leading-9 font-semibold mt-4'>
                  Inventory
                </h1>
                <div className='grid grid-cols-12 gap-4'>
                  <div className='col-span-12 lg:col-span-8'>
                    <ShipmentsBarChart data={shipmentsChartData} />
                  </div>
                  <div className='col-span-12 lg:col-span-4 '>
                    <ShipmentTypePieChart data={shipmentsTypes} />
                  </div>
                </div>

                <div className='mt-4'>
                  <RecentOrders />
                </div>
              </div>
            </div>
          )}
        </Page.Body>
      </Page>
    </>
  )
}

export default AreaDashbaord
