import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import useToast from '../../hooks/useToast'
import { ReactComponent as Truck } from '../../assets/truck.svg'
import { IoIosSearch } from 'react-icons/io'
import manifestApi from '../../api/manifest'
import { fetchRiders } from '../../slices/ridersSlice'
import Modal from 'react-modal'
import { ReactComponent as Times } from '../../assets/times.svg'

const style = {
  content: {
    inset: '16px',
    borderRadius: '20px',
    maxWidth: '450px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%',
    overflow: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function AssignShipmentToDriver ({ isOpen, onClose, shipment }) {
  const dispatch = useDispatch()
  const ridersData = useSelector(state => state?.riders?.riders)
  const toast = useToast()
  const [riderId, setRiderId] = useState('')
  const [riderName, setRiderName] = useState('')
  const [checkSelected, setCheckSelected] = useState('')
  const [loading2, setLoading] = useState(false)
  const [riderSearchedVal, setRiderSearchedVal] = useState('')

  useEffect(() => {
    dispatch(fetchRiders())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const riders = useMemo(() => {
    if (ridersData?.length) {
      if (riderSearchedVal) {
        return ridersData?.filter(item =>
          item?.name.toLowerCase().includes(riderSearchedVal.toLowerCase())
        )
      }

      return ridersData
    }

    return []
  }, [riderSearchedVal, ridersData])

  const handleSelectRider = rider => {
    setRiderId(rider.id)
    setRiderName(rider.name)
    setCheckSelected(rider.id)
    localStorage.setItem('riderName', rider.name)
  }

  const handleAssign = async () => {
    setLoading(true)
    const result = await manifestApi.assignManifest(shipment.id, {
      manifest_type: 'CM',
      rider: { id: riderId, name: riderName }
    })

    if (result.ok) {
      toast('Rider assigned', 'success')
      setLoading(false)
    } else {
      toast(result?.problem, 'error')
      setLoading(false)
    }
  }

  return (
    // <Popover>
    //   <PopoverButton>Assign to drivers</PopoverButton>
    //   <PopoverPanel
    //     transition
    //     anchor='bottom start'
    //     className='transition duration-200 focus:outline-none w-[300px] ease-in-out [--anchor-gap:var(--spacing-5)] data-[closed]:-translate-y-1 data-[closed]:opacity-0 shadow-lg ring-1 ring-black/5 bg-white py-4 overflow-hidden'
    //   >
    <Modal
      style={style}
      isOpen={isOpen}
      onRequestClose={onClose}
      appElement={document.getElementById('root')}
    >
      <div className='text-sm'>
        <div className='flex items-center w-full justify-between'>
          <h3 className='text-lg text-dark-primary font-medium pl-4'>
            Assign Shipment "{shipment.tracking_id}" to Driver
          </h3>

          <div
            className='w-9 h-9 hover:border-solid hover:border-[0.75px] hover:border-gray-500 cursor-pointer'
            onClick={onClose}
          >
            <Times />
          </div>
        </div>

        <div className='flex w-full p-4 h-6'>
          <input
            type='text'
            placeholder='Search for an astronaut'
            className='w-[85%] h-full bg-white border border-[#E5E4E5] rounded-l-xl text-sm'
            onChange={({ target }) => setRiderSearchedVal(target.value)}
          />
          <span className='w-[15%] h-full bg-[#E5E4E5] rounded-r-xl flex justify-center items-center'>
            <IoIosSearch size={20} />
          </span>
        </div>
        <div className='overflow-y-scroll max-h-40'>
          {riders?.map(rider => (
            <span
              className={`flex items-center space-x-4 p-4 cursor-pointer hover:bg-[#FCE3C7] ${
                checkSelected === rider?.id ? 'bg-main-hover' : 'bg-white'
              }`}
              onClick={handleSelectRider}
            >
              <Truck />
              <p>{rider.name}</p>
            </span>
          ))}
        </div>

        <div className='w-full flex justify-end mt-12'>
          <span
            className='flex space-x-2 justify-center items-center w-[125px] h-[50px] bg-main-primary rounded-[100px] cursor-pointer'
            onClick={handleAssign}
          >
            <p className='text-white text-xs'>
              {loading2 === true ? 'Loading...' : 'Assign'}
            </p>
          </span>
        </div>
      </div>
    </Modal>

    //   </PopoverPanel>
    // </Popover>
  )
}
