import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import salesforceApi from '../api/salesforce'
import { parseError, urlQueryStringToObject } from '../utils'

const initialState = {
  agents: {
    data: null,
    meta: {
      previous: 0,
      page: 1,
      next: 0,
      page_size: 50
    }
  },
  salesforceAgent: {
    data: null,
    commissions: {
      data: null,
      meta: {
        previous: 0,
        page: 1,
        next: 0,
        page_size: 50
      }
    }
  }
}

export const fetchSalesforceAgents = createAsyncThunk(
  'salesforceAgents/fetch',
  async (params = {}) => {
    const query = Object.assign({ page_size: 50, page: 1 }, params)

    const response = await salesforceApi.getSalesforce(query)

    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        return {
          type: 'error',
          error: apiError
        }
      }
    }

    return { response_data: response.data, query_params: query }
  }
)

export const fetchSalesforceAgent = createAsyncThunk(
  'salesforceAgent/fetch',
  async id => {
    const response = await salesforceApi.getSingleSalesforce(id)

    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        return {
          type: 'error',
          error: apiError
        }
      }
    }

    return response.data
  }
)

export const fetchSalesforceAgentCommisions = createAsyncThunk(
  'salesforceAgentCommissions/fetch',
  async ({ id, params }) => {
    const query = Object.assign({ page_size: 50, page: 1 }, params)

    const response = await salesforceApi.getCommisions(id, query)

    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        return {
          type: 'error',
          error: apiError
        }
      }
    }

    return { response_data: response.data, query_params: query }
  }
)

const salesforceSlice = createSlice({
  name: 'salesforce',
  initialState,
  reducers: {
    cleanupAgent: state => {
      state.salesforceAgent = {
        data: null,
        commissions: {
          data: null,
          meta: {
            previous: 0,
            page: 1,
            next: 0,
            page_size: 50
          }
        }
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSalesforceAgents.fulfilled, (state, action) => {
        if (action.payload.type !== 'error') {
          const meta = action.payload?.response_data?.meta || {}
          if (meta.next) {
            meta.next = urlQueryStringToObject(meta.next).page
          }
          meta.page = action.payload?.query_params.page
          meta.page_size = action.payload.query_params?.page_size
          meta.previous = meta.page > 1 ? meta.page - 1 : 0
          state.agents.meta = Object.assign({}, state.agents.meta, meta)
          state.agents.data = action.payload?.response_data.payload
        }
      })
      .addCase(fetchSalesforceAgent.fulfilled, (state, action) => {
        if (action.payload.type !== 'error') {
          state.salesforceAgent.data = action?.payload
        }
      })
      .addCase(fetchSalesforceAgentCommisions.fulfilled, (state, action) => {
        if (action.payload.type !== 'error') {
          const meta = action.payload?.response_data?.meta || {}
          if (meta.next) {
            meta.next = urlQueryStringToObject(meta.next).page
          }
          meta.page = action.payload?.query_params.page
          meta.page_size = action.payload.query_params?.page_size
          meta.previous = meta.page > 1 ? meta.page - 1 : 0
          state.salesforceAgent.commissions.meta = Object.assign(
            {},
            state.salesforceAgent.commissions.meta,
            meta
          )
          state.salesforceAgent.commissions.data =
            action?.payload.response_data.payload
        }
      })
  }
})

export const salesforceActions = salesforceSlice.actions

export default salesforceSlice.reducer
