import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import banksApi from '../api/banks'
import { parseError } from '../utils'

const initialState = {
  data: null
}

export const fetchBanks = createAsyncThunk('banks/fetch', async () => {
  const response = await banksApi.getBanks()

  if (!response.ok) {
    const apiError = parseError(response)
    if (apiError) {
      return {
        type: 'error',
        error: apiError
      }
    }
  }

  return response.data
})

const banksSlice = createSlice({
  name: 'banks',
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchBanks.fulfilled, (state, action) => {
      state.data = action.payload
    })
  }
})

export const bankActions = banksSlice.actions

export default banksSlice.reducer
