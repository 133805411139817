import { SuccessPopup } from '../globals'

export default function CreateAgentSuccessful ({
  isOpen,
  onClose,
  agent
}) {
  return (
    <SuccessPopup
      isOpen={isOpen}
      title='Created Salesforce Agent Successfully!'
      description={
        <>
          You have successfully created a saleforce agent with the name
          <span className='font-medium'>{agent.name}</span> and email{' '}
          <span className='font-medium'>{agent.email}</span>.
          <br />A notification has been sent to the agent's email containing the
          agent's code.
        </>
      }
      onClose={onClose}
    />
  )
}
