import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDrafts } from '../../slices/ordersSlice'
import Page from '../../containers/Page'
import { AiOutlineDelete } from 'react-icons/ai'
import Pagination from '../../components/globals/pagination/ServerPagination'
import { getDate, getStateOfCountry } from '../../utils'
import Search from '../../components/globals/Search/Search'
import { HiOutlinePencil } from 'react-icons/hi2'
import OrderDraftModal from '../../components/orders/drafts/OrderDraftModal'
import ConfirmDelete from '../../components/orders/confirmDelete'
import ordersApi from '../../api/orders'
import useToast from '../../hooks/useToast'
import { Table } from '../../components'
import ROUTES from '../../constants/routes'

const breadCrumbs = [
  {
    name: 'Orders',
    path: ROUTES.ORDERS.path
  },
  {
    name: 'Draft Orders',
    path: '#',
    disabled: true
  }
]

export default function OrderDrafts ({ metaTitle }) {
  const [checkedList, setCheckedList] = useState([])
  const [serializedData, setSerializedData] = useState(null)
  const [searchValue, setSearchValue] = useState('')
  const [searchBy, setSearchBy] = useState('id')
  const [editModal, setEditModal] = useState({
    isOpen: false,
    order_id: null
  })
  const [deletePrompt, setDeletePrompt] = useState({
    isOpen: false,
    type: '',
    order_id: null
  })
  const [isDeleting, setDeleting] = useState(false)

  const dispatch = useDispatch()
  const toast = useToast()

  const draftsStore = useSelector(state => state.orders.drafts)

  const drafts = useMemo(() => serializedData, [serializedData])

  const loadDrafts = useCallback(params => {
    dispatch(fetchDrafts(params))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setSerializedData(null)
    loadDrafts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const searchOptions = [
    {
      name: 'Order ID',
      value: 'id'
    },
    {
      name: 'Sender',
      value: 'sender_name'
    },
    {
      name: 'Receiver',
      value: 'receiver_name'
    }
  ]

  const selectedDrafts = useMemo(() => {
    const list = []
    for (let order in checkedList) {
      if (checkedList[order]) {
        list.push(order)
      }
    }

    return list
  }, [checkedList])

  const handleSelectItem = list => {
    const itemsList = []

    for (let s_n in list) {
      // eslint-disable-next-line eqeqeq
      let existingItem = checkedList.find(item => item.s_n == s_n)
      if (existingItem) {
        itemsList.push(existingItem)
        continue
      }

      // eslint-disable-next-line eqeqeq
      let draft = serializedData.find(item => item.s_n == s_n)
      itemsList.push(draft)
    }

    setCheckedList(itemsList)
  }

  const onPage = params => {
    setSerializedData(null)
    loadDrafts(params)
  }

  const onSearchChange = ({ target }) => {
    setSearchValue(target.value)
  }

  const searchInputPlaceHolder = useMemo(() => {
    switch (searchBy) {
      case 'id':
        return 'Enter order ID'
      case 'sender_name':
        return 'Enter sender name'
      case 'receiver_name':
        return 'Enter receiver name'
      default:
        break
    }
  }, [searchBy])

  const handleOrderClick = order => {
    setEditModal({
      isOpen: true,
      order_id: order.id
    })
  }

  const handleCloseModal = () => {
    setEditModal({
      isOpen: false,
      order_id: null
    })
  }

  const handleEdit = order_id => {
    setEditModal({
      isOpen: true,
      order_id
    })
  }

  const handleDelete = async () => {
    const deleteOrder = async id => {
      const response = await ordersApi.deleteOrder(id)
      return response
    }

    const onComplete = type => {
      toast(`Draft${type === 'multiple' ? 's' : ''} deleted`, 'success')
      setDeleting(false)
      closeDeletePrompt()
      loadDrafts()
    }

    setDeleting(true)

    if (deletePrompt.type === 'single') {
      await deleteOrder(deletePrompt.orders[0])
      onComplete(deletePrompt.type)
    } else {
      let i = 0
      while (i < deletePrompt.orders.length) {
        await deleteOrder(deletePrompt.orders[i])
        i++
      }
      onComplete(deletePrompt.type)
    }
  }

  const openDeletePrompt = order_id => {
    if (order_id) {
      setDeletePrompt({
        isOpen: true,
        type: 'single',
        orders: [order_id]
      })
    } else {
      const orders = Object.entries(checkedList)
        .filter(([key, value]) => !!value)
        .map(([key, value]) => key)

      setDeletePrompt({
        isOpen: true,
        type: 'multiple',
        orders
      })
    }
  }

  const closeDeletePrompt = () => {
    setDeletePrompt({
      isOpen: false,
      type: '',
      orders: []
    })
  }

  const tableHeader = ['S/N', 'ID', 'Details', 'Last Updated', 'Actions']

  const tableData = useMemo(() => {
    return drafts?.map(order => ({
      ...order,
      'S/N': order.s_n,
      ID: `#${order.id}`,
      Details: (
        <div>
          <p className='flex gap-1 line-clamp-1 truncate'>
            {order.tpl_service && (
              <>
                <span>{order.tpl_service}</span> ·{' '}
              </>
            )}
            {order.weight && (
              <>
                <span>
                  Weight: {order.weight ? `${order.weight}kg` : 'NA'}{' '}
                </span>
                ·{' '}
              </>
            )}
            <span>
              Origin:{' '}
              {order?.sender
                ? `${
                    order?.sender?.address?.state_name ||
                    getStateOfCountry(
                      order?.sender?.address?.state_or_province_code,
                      order?.sender?.address?.country
                    )?.name ||
                    order?.sender?.address?.state_or_province_code
                  }, ${order?.sender?.address?.country} `
                : 'NA'}{' '}
            </span>
            ·{' '}
            <span>
              Destination:{' '}
              {order?.receiver
                ? `${
                    order?.receiver?.address?.state_name ||
                    getStateOfCountry(
                      order?.receiver?.address?.state_or_province_code,
                      order?.receiver?.address?.country
                    )?.name ||
                    order?.receiver?.address?.state_or_province_code
                  }, ${order?.receiver?.address?.country} `
                : 'NA'}{' '}
            </span>
          </p>
        </div>
      ),
      'Last Updated': getDate(order.order_date),
      Actions: (
        <div className='flex items-center w-full justify-center'>
          <button
            className='btn-icon text-main-primary-2 hover:bg-main-hover hover:text-main-primary-2'
            onClick={e => {
              e.stopPropagation()
              handleEdit(order.id)
            }}
          >
            <HiOutlinePencil size={16} />
          </button>
          <button
            className='btn-icon text-error hover:bg-error/20 hover:text-error'
            onClick={e => {
              e.stopPropagation()
              openDeletePrompt(order.id)
            }}
          >
            <AiOutlineDelete size={16} />
          </button>
        </div>
      )
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drafts])

  return (
    <Page metaTitle={metaTitle}>
      <Page.Header
        title='Draft Orders'
        breadCrumbs={breadCrumbs}
        backRoute={ROUTES.ORDERS.path}
      >
        <Search
          value={searchValue}
          multiple={true}
          searchBy={searchBy}
          searchOptions={searchOptions}
          onSearchOptionChange={option => setSearchBy(option)}
          inputPlaceHolder={searchInputPlaceHolder}
          handleSearch={onSearchChange}
          allowServerSearch={false}
        />
      </Page.Header>
      <Page.Body>
        {editModal.isOpen && (
          <OrderDraftModal
            isOpen={editModal.isOpen}
            onClose={handleCloseModal}
            id={editModal.order_id}
          />
        )}
        {deletePrompt.isOpen && (
          <ConfirmDelete
            isOpen={deletePrompt.isOpen}
            text={`Are you sure you want to delete ${
              deletePrompt.type === 'single'
                ? 'this draft?'
                : 'the selected drafts?'
            }`}
            onConfirm={handleDelete}
            onCancel={closeDeletePrompt}
            isDeleting={isDeleting}
          />
        )}
        <div className='mb-3 flex flex-col gap-2 lg:gap-3'>
          <div className='flex gap-2 flex-row flex-wrap items-center justify-between w-full'>
            <div className='text-sm'>
              {selectedDrafts.length ? (
                <div className='flex gap-2 items-center'>
                  <p>Selected {selectedDrafts.length} items</p>•
                  <button
                    className='btn-icon text-error hover:bg-error/20 hover:text-error'
                    onClick={openDeletePrompt}
                  >
                    <AiOutlineDelete size={16} />
                  </button>
                </div>
              ) : (
                <p>Pick up from where you left off</p>
              )}
            </div>
            <div className='flex items-center gap-3 ml-auto'>
              <Pagination
                tableId='draft-orders-table'
                totalCount={draftsStore.data?.length}
                data={draftsStore.data}
                setSerializedData={setSerializedData}
                onPage={onPage}
                page={draftsStore.meta?.page}
                pageSize={draftsStore.meta?.page_size}
              />
            </div>
          </div>
        </div>

        <Table
          id='shipments-table'
          headers={tableHeader}
          data={tableData}
          rowAction={handleOrderClick}
          emptyDataText='No draft found'
          withCheckbox
          onSelectRow={handleSelectItem}
        />
      </Page.Body>
    </Page>
  )
}
