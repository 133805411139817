import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import transactionsApi from '../api/transactions'
import { parseError, urlQueryStringToObject } from '../utils'

const initialState = {
  initiate: null,
  initiate_error: null,
  confirm_payment: null,
  confirm_payment_error: null,
  logs: null,
  unremitted_cash_transactions: null
}

export const initiateTransaction = createAsyncThunk(
  'transactions/initiate',
  async payload => {
    const response = await transactionsApi.initiate(payload)

    const handleError = error => {
      if (error.data.detail) {
        return {
          type: 'error',
          error: error.data.message ?? error.data.detail.message
        }
      } else if (error.data.order) {
        return { type: 'error', error: error.data.order[0] }
      } else {
        return { type: 'error', error: 'Error initiating transaction' }
      }
    }

    if (!response.ok) {
      const apiError = parseError(response)
      return handleError(apiError)
    }

    if (response.status < 300 && response.data.payload) {
      return { type: 'success', data: response.data.payload }
    } else return handleError()
  }
)

export const fetchTransactions = createAsyncThunk(
  'transactions/fetch',
  async (params = {}) => {
    const query = Object.assign(
      {
        page_size: 50,
        page: 1
      },
      params
    )

    const response = await transactionsApi.fetchTransactions(query)

    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        return {
          type: 'error',
          error: apiError
        }
      }
    }

    return { response_data: response.data, query_params: query }
  }
)

export const confirmCashPayment = createAsyncThunk(
  'transactions/confirmCashPayment',
  async ref => {
    const response = await transactionsApi.confirmCashPayment(ref)

    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        return {
          type: 'error',
          error: apiError.data.detail?.message
            ? `Error: ${apiError.data.detail?.message}`
            : 'Error confirming payment'
        }
      }
    }

    return { type: 'success', data: response.data }
  }
)

export const fetchUnremittedCashTransactions = createAsyncThunk(
  'transactions/unremittedTCashTransactions',
  async ref => {
    const response = await transactionsApi.fetchUnremittedCashTransactions()

    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        return {
          type: 'error',
          error: apiError
        }
      }
    }

    return response.data.payload
  }
)

const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    cleanup: state => {
      state.initiate = null
      state.initiate_error = null
      state.confirm_payment = null
      state.confirm_payment_error = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(initiateTransaction.fulfilled, (state, action) => {
        if (action.payload.type === 'success') {
          state.initiate = action.payload.data
          state.initiate_error = null
        } else {
          state.initiate_error = action.payload?.error
          state.initiate = null
        }
      })
      .addCase(initiateTransaction.rejected, (state, action) => {
        state.initiate = null
        state.initiate_error = action.payload?.error
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        const meta = action.payload?.response_data?.meta || {}

        if (meta.next) {
          meta.next = urlQueryStringToObject(meta.next).page
        }
        meta.page = action.payload?.query_params?.page
        meta.page_size = action.payload?.query_params?.page_size
        meta.previous = meta.page > 1 ? meta.page - 1 : 0
        state.meta = Object.assign({}, state.meta, meta)

        state.logs = action.payload?.response_data?.payload
      })
      .addCase(fetchTransactions.rejected, (state, action) => {
        state.error = action.payload
      })
      .addCase(confirmCashPayment.fulfilled, (state, action) => {
        if (action.payload.type === 'success') {
          state.confirm_payment = action.payload.data
          state.confirm_payment_error = null
        } else {
          state.confirm_payment_error = action.payload?.error
          state.confirm_payment = null
        }
      })
      .addCase(confirmCashPayment.rejected, (state, action) => {
        state.confirm_payment_error = action.payload
        state.confirm_payment = null
      })
      .addCase(fetchUnremittedCashTransactions.fulfilled, (state, action) => {
        state.unremitted_cash_transactions = action.payload
      })
  }
})

export const transactionActions = transactionSlice.actions

export default transactionSlice.reducer
