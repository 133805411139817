import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import dashboardApi from '../api/dashboard'
import { parseError } from '../utils'

const initialState = {
  filter: null,
  data: null,
  error: null
}

export const fetchDashboard = createAsyncThunk(
  'dashboard/fetch',
  async query => {
    const response = await dashboardApi.getDashboard(query)

    if (!response.ok) {
      const apiError = parseError(response)
      if (apiError) {
        return {
          type: 'error',
          error: apiError
        }
      }
    }

    return {
      type: 'success',
      data: response.data
    }
  }
)

export const filterDashboard = () => {
  return {}
}

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    filter (state, action) {
      state.filter = action.payload
    },
    cleanupFilter (state) {
      state.filter = null
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchDashboard.fulfilled, (state, action) => {
      if (action.payload.type === 'success') {
        state.data = action.payload.data
        state.error = null
      } else {
        state.error = action.payload.error
        state.data = null
      }
    })
  }
})

export const dashboardActions = dashboardSlice.actions

export default dashboardSlice.reducer
