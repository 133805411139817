import { useEffect, useState } from 'react'
import noDataIcon from '../../assets/no-data.webp'
import { HiOutlineDotsVertical } from 'react-icons/hi'
import Checkbox from './Checkbox'
import Unselect from './Unselect'
import { twMerge } from 'tailwind-merge'
import Button from './Button'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import Loader from './loader'

function Table ({
  variant = 'elevated',
  id,
  headers,
  data,
  rowAction,
  emptyDataText,
  emptyDataAction = null,
  emptyDataActionText,
  withMenu,
  withCheckbox,
  onSelectRow,
  rowMenuItems
}) {
  const [selectedAll, setSelectedAll] = useState(false)
  const [checkedList, setCheckedList] = useState({})

  useEffect(() => {
    if (selectedAll) {
      unSelectAll()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const toggleSelectAll = () => {
    const newValue = !selectedAll
    setSelectedAll(newValue)
    if (newValue) {
      selectAll()
    } else {
      unSelectAll()
    }
  }

  const toggleItemCheck = (value, s_n) => {
    const list = { ...checkedList }
    if (value) {
      list[s_n] = true
    } else {
      delete list[s_n]
    }

    if (Object.keys(list).length === 0 && selectedAll) {
      setSelectedAll(false)
    }
    setCheckedList(list)
    onSelectRow(list)
  }

  const selectAll = () => {
    const list = data.reduce((acc, item) => {
      if (item.checkDisabled) {
        return acc
      }

      return { ...acc, [item.s_n]: true }
    }, {})
    setCheckedList(list)
    onSelectRow(list)
  }

  const unSelectAll = () => {
    setCheckedList({})
    onSelectRow({})
  }

  return (
    <div>
      <div
        className={twMerge(
          'data-table-wrapper',
          variant === 'flat'
            ? 'data-table-wrapper--flat'
            : 'data-table-wrapper--elevated'
        )}
      >
        {!data ? (
          <div className='py-14'>
            <Loader page={false} />
          </div>
        ) : (
          <>
            {!!data.length && (
              <table
                id={id}
                className={twMerge(
                  'table--striped',
                  rowAction && 'table--hover'
                )}
              >
                <thead>
                  <tr>
                    {withCheckbox && (
                      <th>
                        {Object.keys(checkedList).length && !selectedAll ? (
                          <Unselect onClick={unSelectAll} />
                        ) : (
                          <Checkbox
                            checked={selectedAll}
                            onChange={toggleSelectAll}
                          />
                        )}
                      </th>
                    )}
                    {headers.map((item, index) => (
                      <th key={index}>{item}</th>
                    ))}
                    {withMenu && <th></th>}
                  </tr>
                </thead>
                <tbody>
                  {data.map((row, rowIndex) => (
                    <tr
                      key={rowIndex}
                      onClick={rowAction ? () => rowAction(row) : null}
                    >
                      {withCheckbox && (
                        <td>
                          <span onClick={e => e.stopPropagation()}>
                            <Checkbox
                              checked={!!checkedList[row.s_n]}
                              onChange={value =>
                                toggleItemCheck(value, row.s_n)
                              }
                              disabled={row.checkDisabled}
                            />
                          </span>
                        </td>
                      )}
                      {headers.map((column, columnIndex) => (
                        <td key={columnIndex}>{row[column] || '—'} </td>
                      ))}
                      {withMenu && (
                        <td>
                          <Table.RowMenu row={row} menutItems={rowMenuItems} />
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {!data.length && (
              <div className='py-14 w-full flex flex-col gap-2 items-center justify-center mx-auto'>
                <img
                  src={noDataIcon}
                  className='w-40 h-40 object-contain'
                  alt='no data icon'
                />
                <p className='no_data_description_text'>{emptyDataText}</p>
                {emptyDataAction && (
                  <div className='mt-4'>
                    <Button
                      text={emptyDataActionText}
                      onClick={emptyDataAction}
                    />
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default Table

Table.RowMenu = function TableRowMenu2 ({ row, menutItems }) {
  return (
    <div onClick={e => e.stopPropagation()}>
      <Menu>
        <MenuButton className='flex items-center bg-transparent data-[hover]:bg-g-100 data-[open]:bg-g-100 data-[focus]:bg-g-100 rounded-full p-1.5 focus:outline-none data-[focus]:outline-1'>
          <HiOutlineDotsVertical size={16} />
        </MenuButton>

        <MenuItems
          transition
          anchor='bottom end'
          className='w-52 origin-top-right bg-white rounded-md max-h-80 shadow-lg ring-1 ring-black/5 text-sm py-2 overflow-x-hidden transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0'
        >
          {menutItems.map((item, id) => {
            return (
              <MenuItem key={id}>
                <Table.RowMenuItem item={item} row={row} />
              </MenuItem>
            )
          })}
        </MenuItems>
      </Menu>
    </div>
  )
}

Table.RowMenuItem = function TableRowMenuItme ({ item, row }) {
  const [isHover, setHover] = useState(false)

  const toggleHover = () => setHover(state => !state)

  const handleClick = e => {
    e.stopPropagation()
    item.action(row)
  }

  return (
    <div
      className={twMerge(
        `flex flex-row w-full items-center gap-2 py-1.5 px-3 rounded-none ${
          item.color === 'danger'
            ? 'hover:bg-error/20 hover:text-error'
            : 'hover:bg-main-hover hover:text-main-primary-2'
        }`
      )}
      role='button'
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
      onClick={handleClick}
    >
      <span className='p-0 w-4 h-4'>
        <item.icon
          size={16}
          width={16}
          height={16}
          variant={isHover ? 'primary' : 'secondary'}
        />
      </span>
      <span className='w-[calc(100%-1.5rem)] p-0 truncate'>{item.name}</span>
    </div>
  )
}
