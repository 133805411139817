import { ReactComponent as Times } from '../../../assets/times.svg'
import SelectDropdown from '../selectDropdown'
import { ReactComponent as Caret } from '../../../assets/dropdown-caret.svg'
import { useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import useOnClickOutside from '../../../hooks/useOnClickOutside'
import { ReactComponent as FilterIcon } from '../../../assets/filter.svg'
import Modal from 'react-modal'

const style = {
  content: {
    inset: '16px',
    borderRadius: '20px',
    maxWidth: '450px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%',
    overflow: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function Filter ({ Component, ...restProps }) {
  const [isOpen, setOpen] = useState(false)

  const openFilter = () => setOpen(true)

  const closeFilter = () => setOpen(false)

  return (
    <>
      <button
        className={twMerge(
          'bg-white rounded-lg p-2 w-12 sm:w-24 flex flex-nowrap whitespace-nowrap items-center justify-center text-g-600 hover:bg-[#E5E4E5] text-sm h-[2.5rem] gap-1 border',
          isOpen ? 'border-primary' : ''
        )}
        onClick={openFilter}
      >
        <FilterIcon />
        <span className='hidden sm:inline-block'>Filter by</span>
      </button>
      {isOpen && (
        <Component isOpen={isOpen} onClose={closeFilter} {...restProps} />
      )}
    </>
  )
}

Filter.Activator = function FilterActivator ({
  onClick,
  Component,
  ...restProps
}) {
  const [isOpen, setOpen] = useState(false)

  const openFilter = () => setOpen(true)

  const closeFilter = () => setOpen(false)

  return (
    <>
      <button
        className={twMerge(
          'bg-white rounded-lg p-2 w-12 sm:w-24 flex flex-nowrap whitespace-nowrap items-center justify-center text-g-600 hover:bg-[#E5E4E5] text-sm h-[2.5rem] gap-1 border',
          isOpen ? 'border-primary' : ''
        )}
        onClick={openFilter}
      >
        <FilterIcon />
        <span className='hidden sm:inline-block'>Filter by</span>
      </button>
      {isOpen && (
        <Component isOpen={isOpen} onClose={closeFilter} {...restProps} />
      )}
    </>
  )
}

Filter.Body = function FilterBody ({ isOpen, onClose, onCancel, children }) {
  return (
    <Modal
      style={style}
      isOpen={isOpen}
      onRequestClose={onClose}
      appElement={document.getElementById('root')}
    >
      <div>
        <div className='flex items-center w-full justify-between'>
          <h3 className='text-lg text-dark-primary font-medium pl-4'>Filter</h3>

          <div
            className='w-9 h-9 hover:border-solid hover:border-[0.75px] hover:border-gray-500 cursor-pointer'
            onClick={onCancel}
          >
            <Times />
          </div>
        </div>
        <div className='flex flex-col p-4 gap-6 text-sm text-dark-primary'>
          {children}
        </div>
      </div>
    </Modal>
  )
}

Filter.SelectDropdown = function FilterSelectDropdown ({
  title,
  value,
  placeholder,
  options,
  onChange,
  disabled = false
}) {
  const [show, setShow] = useState(false)

  const toggleDropdown = () => setShow(state => !state)

  const onSelect = val => {
    onChange(val)
    setShow(false)
  }

  const dropdownRef = useRef(null)

  useOnClickOutside(dropdownRef, show, toggleDropdown)

  return (
    <div
      className={twMerge(
        'flex flex-col gap-4 transition-all',
        disabled ? 'opacity-50' : 'opacity-100'
      )}
      ref={dropdownRef}
    >
      <div>{title}</div>
      <div className='w-full flex items-center relative'>
        <div
          className={twMerge(
            'flex items-center justify-between gap-2 border-[0.5px] border-gray-200 w-full p-2 rounded-md text-[#333333b3]',
            disabled ? 'cursor-default' : 'cursor-pointer'
          )}
          onClick={disabled ? null : toggleDropdown}
        >
          <span>
            {value
              ? options.find(opt => opt.value === value)?.name
              : placeholder}
          </span>
          <Caret />
        </div>
        <SelectDropdown
          show={show}
          options={options}
          selected={value}
          onSelect={onSelect}
        />
      </div>
    </div>
  )
}

Filter.Radio = function FilterRadio ({ title, name, value, options, onChange }) {
  return (
    <div className='flex flex-col gap-4'>
      <div>{title}</div>
      <div className='flex flex-col gap-3'>
        {options.map(({ name: _name, value: _value }, id) => (
          <label
            htmlFor={_name}
            className='flex items-center gap-4 cursor-pointer'
            key={id}
          >
            <input
              type='radio'
              name={name}
              className='radio radio-primary rounded-md'
              value={_value}
              checked={value === `${_value}`}
              onChange={({ target }) => onChange(target.value)}
              id={_name}
            />
            <span className='text-[#333333b3]'>{_name}</span>
          </label>
        ))}
      </div>
    </div>
  )
}

Filter.CheckBox = function FilterCheckBox ({ title, name, checked, onChange }) {
  return (
    <div className='flex flex-col gap-4'>
      <label htmlFor={name} className='flex items-center gap-4 cursor-pointer'>
        <input
          type='checkbox'
          checked={checked}
          name={name}
          className='checkbox checkbox-primary rounded-md'
          onChange={onChange}
        />
        <span className='text-[#333333b3]'>{title}</span>
      </label>
    </div>
  )
}

Filter.Submit = function FilterSubmit ({
  disabled,
  onSubmit,
  text = 'Apply Filters'
}) {
  return (
    <div className='mt-10 ml-auto'>
      <button
        className={twMerge(
          'btn btn-rounded min-w-24',
          disabled ? 'btn-neutral btn-disabled' : 'btn-primary'
        )}
        disabled={disabled}
        onClick={onSubmit}
      >
        {text}
      </button>
    </div>
  )
}
