import { FaCheck } from 'react-icons/fa'
import { twMerge } from 'tailwind-merge'

export default function SelectDropdown ({
  show,
  options,
  selected,
  onSelect,
  footer,
  width = 'w-60'
}) {
  return (
    <div
      className={twMerge(
        'shadow-lg py-4 absolute bg-white origin-top top-full max-h-[300px] overflow-y-auto border transition-all z-10 rounded-md text-sm',
        width,
        show ? 'scale-y-1' : 'scale-y-0'
      )}
    >
      <ul className='divide-y'>
        {options.map((option, id) => (
          <li
            tabIndex={0}
            key={id}
            onClick={() => onSelect(option.value)}
            className={`py-2 pl-4 pr-4 bg-white hover:bg-g-100 transition-all text-dark-primary cursor-pointer w-full relative`}
          >
            <div>{option.name}</div>
            {/* eslint-disable-next-line eqeqeq */}
            {selected == option.value && (
              <span className='absolute top-2.5 right-4'>
                <FaCheck size={14} color={'#fe6802'} />
              </span>
            )}
          </li>
        ))}
      </ul>

      {footer && (
        <div className='mt-4 border-t border-[#F2F2F2] pt-2'>
          <div
            className={`py-2 px-4 ${
              // eslint-disable-next-line eqeqeq
              selected == footer.value
                ? 'bg-[#FFF6D7]'
                : 'bg-white hover:bg-g-100'
            } transition-all text-dark-primary cursor-pointer`}
            onClick={() => onSelect(footer.value)}
          >
            {footer.name}
          </div>
        </div>
      )}
    </div>
  )
}
