import React, { useEffect, useMemo, useRef, useState } from 'react'
import logo from '../../assets/logo-text.png'
import { Link, useLocation } from 'react-router-dom'
import ROUTES, { routeGroups } from '../../constants/routes.js'
import ROLES from '../../constants/roles.js'
import { headerShadowOnScroll } from '../../utils/animate.js'
import { MdArrowDropDown } from 'react-icons/md'
import { twMerge } from 'tailwind-merge'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Transition
} from '@headlessui/react'
import { useSelector } from 'react-redux'
import packageJson from '../../package.alias.json'
import {
  DashboardIcon,
  DraftsIcon,
  FleetIcon,
  InvoicesIcon,
  ManifestsIcon,
  OrdersIcon,
  QuotesIcon,
  ReceiptsIcon,
  ReportsIcon,
  SalesforceIcon,
  SettingsIcon,
  ShipmentsIcon,
  TrackingIcon,
  TransactionsIcon,
  UsersIcon,
  WarehouseIcon
} from '../icons'

const NavChildItem = ({ link }) => {
  const [isHover, setHover] = useState(false)

  const location = useLocation()

  const isActive = useMemo(
    () => location.pathname.includes(link.path),
    [link.path, location.pathname]
  )

  const toggleHover = () => {
    setHover(state => !state)
  }

  return (
    <li className='w-full'>
      <div
        className={twMerge(
          'w-full bg-white flex items-center gap-2.5',
          isActive ? 'text-primary' : ''
        )}
      >
        <Link to={link.path} className='block w-full'>
          <div
            className={twMerge(
              'flex items-center gap-2.5 px-2.5 py-2.5 w-full',
              isActive ? 'lg:text-primary' : 'hover:text-primary'
            )}
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
          >
            <link.icon
              className='w-5 h-5'
              variant={isActive || isHover ? 'primary' : 'secondary'}
            />
            <span className='text-left inline-block truncate w-[calc(100%-3.125rem)]'>
              {link.name}
            </span>
          </div>
        </Link>
      </div>
    </li>
  )
}

const NavItem = ({ link }) => {
  const [isHover, setHover] = useState(false)

  const location = useLocation()

  const toggleHover = () => {
    setHover(state => !state)
  }

  const isActive = useMemo(() => {
    return location.pathname.includes(link.path)
  }, [link.path, location.pathname])

  if (link.disabled) {
    return (
      <button disabled className='opacity-60 cursor-not-allowed'>
        <div className={`flex items-center gap-2.5 px-2.5 py-[15px] rounded`}>
          <span>
            <link.icon
              className='w-5 h-5'
              variant={isActive || isHover ? 'primary' : 'secondary'}
            />
          </span>
          <span>{link.name}</span>
        </div>
      </button>
    )
  }

  return (
    <Disclosure as='div'>
      {() => {
        return (
          <>
            <div className='flex rounded overflow-hidden w-full'>
              <Link
                to={link.path}
                className={twMerge(
                  'block ',
                  link.children ? 'w-[calc(100%-2.5rem)]' : 'w-full'
                )}
              >
                <div
                  className={twMerge(
                    'flex items-center gap-2.5 px-2.5 py-2.5 w-full',
                    isActive
                      ? 'bg-accent lg:text-primary'
                      : 'hover:text-primary'
                  )}
                  onMouseEnter={toggleHover}
                  onMouseLeave={toggleHover}
                >
                  <link.icon
                    className='w-5 h-5'
                    variant={isActive || isHover ? 'primary' : 'secondary'}
                  />
                  <span className='text-left inline-block truncate w-[calc(100%-3.125rem)]'>
                    {link.name}
                  </span>
                </div>
              </Link>

              {link.children && (
                <div className='w-10'>
                  <DisclosureButton
                    className={twMerge(
                      'py-2.5 w-full h-full flex items-center justify-center ',
                      isActive
                        ? 'text-white bg-primary hover:bg-main-primary'
                        : 'hover:bg-main-hover'
                    )}
                  >
                    <MdArrowDropDown size={18} />
                  </DisclosureButton>
                </div>
              )}
            </div>
            {link.children && (
              <Transition
                enter='transition origin-top duration-150 ease-out'
                enterFrom='transform scale-y-0 opacity-0'
                enterTo='transform scale-y-100 opacity-100'
                leave='transition origin-top duration-100 ease-out'
                leaveFrom='transform scale-y-100 opacity-100'
                leaveTo='transform scale-y-0 opacity-0'
              >
                <DisclosurePanel as='div'>
                  <ul className='pl-[1.875rem]'>
                    {link.children.map((_link, idx) => {
                      return <NavChildItem link={_link} key={idx} />
                    })}
                  </ul>
                </DisclosurePanel>
              </Transition>
            )}
          </>
        )
      }}
    </Disclosure>
  )
}

const Navbar = ({ domain }) => {
  const userData = useSelector(state => state.auth.user)

  const links = useMemo(() => {
    const navLinks = {}

    userData?.user?.roles.forEach(role_id => {
      const routes = ROLES[role_id].routes
      routes.forEach(routeGroup => {
        // eslint-disable-next-line default-case
        switch (routeGroup) {
          case routeGroups.DASHBOARD:
            navLinks.dashboard = {
              icon: DashboardIcon,
              path: ROUTES.DASHBOARD.path,
              name: 'Dashboard',
              serial_no: 1
            }
            break
          case routeGroups.QUOTES:
            navLinks.quotes = {
              icon: QuotesIcon,
              path: ROUTES.QUOTES.path,
              name: 'Quotes',
              searial_no: 2
            }
            break
          case routeGroups.ORDERS:
            navLinks.orders = {
              icon: OrdersIcon,
              path: ROUTES.ORDERS.path,
              name: 'Orders',
              serial_no: 3,
              children: [
                // {
                //   icon: Orders,
                //   path: ROUTES.ORDERS.path,
                //   name: 'Orders'
                // },
                {
                  icon: DraftsIcon,
                  path: ROUTES.ORDERS.DRAFTS.path,
                  name: 'Drafts'
                }
              ]
            }
            break
          case routeGroups.SHIPMENTS:
            navLinks.shipments = {
              icon: ShipmentsIcon,
              path: ROUTES.SHIPMENTS.path,
              name: 'Shipments',
              serial_no: 4
            }
            break
          case routeGroups.INVOICES:
            navLinks.invoices = {
              icon: InvoicesIcon,
              path: ROUTES.INVOICES.path,
              name: 'Invoices',
              serial_no: 5
            }
            break
          case routeGroups.RECEIPTS:
            navLinks.receips = {
              icon: ReceiptsIcon,
              path: ROUTES.RECEIPTS.path,
              name: 'Receipts',
              serial_no: 6
            }
            break
          case routeGroups.TRACK:
            navLinks.track = {
              icon: TrackingIcon,
              path: ROUTES.TRACK.path,
              name: 'Tracking',
              serial_no: 7
            }
            break
          case routeGroups.TRANSACTIONS:
            navLinks.transactions = {
              icon: TransactionsIcon,
              path: ROUTES.TRANSACTIONS.path,
              name: 'Transactions',
              serial_no: 8
            }
            break
          case routeGroups.SALESFORCE:
            navLinks.salesforce = {
              icon: SalesforceIcon,
              path: ROUTES.SALESFORCE.path,
              name: 'Salesforce',
              serial_no: 9
            }
            break
          case routeGroups.MANIFEST:
            navLinks.manifest = {
              icon: ManifestsIcon,
              path: ROUTES.MANIFEST.path,
              name: 'Manifest',
              serial_no: 10
            }
            break
          case routeGroups.USERS:
            navLinks.users = {
              icon: UsersIcon,
              path: ROUTES.USERS.path,
              name: 'Users',
              serial_no: 11,
              disabled: true
            }
            break
          case routeGroups.REPORTS:
            navLinks.reports = {
              icon: ReportsIcon,
              path: ROUTES.REPORTS.path,
              name: 'Reports',
              serial_no: 12,
              disabled: true
            }
            break
          case routeGroups.FLEET:
            navLinks.fleet = {
              icon: FleetIcon,
              path: ROUTES.FLEET.path,
              name: 'Fleet',
              serial_no: 13,
              disabled: true
            }
            break
          case routeGroups.INVENTORY:
            navLinks.inventory = {
              icon: WarehouseIcon,
              path: ROUTES.INVENTORY.path,
              name: 'Inventory & Warehouse',
              serial_no: 14,
              disabled: true
            }
            break
          case routeGroups.ACCOUNT_SETTINGS:
            navLinks.account_settings = {
              icon: SettingsIcon,
              path: ROUTES.ACCOUNT_SETTINGS.path,
              name: 'Account Settings',
              serial_no: 15
            }
        }
      })
    })

    let navLinksArr = Object.values(navLinks).sort(
      (a, b) => a.serial_no - b.serial_no
    )
    return navLinksArr
  }, [userData])

  const stickyHeaderRef = useRef(null)

  useEffect(() => {
    if (stickyHeaderRef.current) {
      headerShadowOnScroll(stickyHeaderRef.current)
    }
  }, [])

  const domainIndicator = useMemo(() => {
    // eslint-disable-next-line default-case
    switch (domain?.name) {
      case 'branch':
        return `Branch:  ${userData?.branch?.name}`
      case 'area':
        return `Area: ${userData?.branch?.name}`
      case 'region':
        return `Region: ${userData?.branch?.name}`
      case 'all-brances':
        return ''
    }
  }, [domain, userData])

  return (
    <nav className='bg-white z-[1] h-full overflow-y-auto border-r border-[#B6B6B64D] shadow-md-right'>
      <div className='py-2 w-full'>
        <div
          className='flex flex-col items-start bg-white pt-4 pb-2 pl-4 pr-1 sticky-header'
          ref={stickyHeaderRef}
        >
          <div className='w-fit mb-5'>
            <Link to={ROUTES.HOME.path} className='cursor-pointer'>
              <img
                src={logo}
                alt='logo'
                className='w-12 h-auto lg:w-16 lg:h-auto'
              />
            </Link>
            <div>
              <span className='font-medium uppercase text-xs select-none text-dark-primary/60 whitespace-nowrap'>
                Transport Pro{' '}
              </span>
              <span className='text-2xs ml-2 whitespace-nowrap text-dark-primary/60 font-medium tracking-wide'>
                v{packageJson.version}
              </span>
            </div>
          </div>
          {domainIndicator && (
            <span className='text-sm lg:text-[13px] p-1.5 rounded-xl select-none bg-gray-100'>
              {domainIndicator}
            </span>
          )}
        </div>
        <div className='flex flex-col gap-2 py-2 text-[14px] pl-4 pr-1'>
          {links.map((link, idx) => (
            <NavItem link={link} key={idx} disabled={link.disabled} />
          ))}
        </div>
      </div>
    </nav>
  )
}

export default Navbar
