import { toast } from 'react-toastify'
import { Country, State } from 'country-state-city'
import cookies from './cookies'
import store from '../store'
import { authActions } from '../slices/authSlice'
import dayjs from 'dayjs'

export const IS_ALL_NUMBERS = /^\d+$/

export const removeComma = input => {
  if (typeof input === 'string') {
    return input.replace(/,/g, '')
  }
  return input
}

export const getDate = dateString => {
  if (dateString) {
    const date = new Date(dateString)

    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hours = date.getHours()
    const minutes = date.getMinutes()

    const formatNumbering = num => {
      return num < 10 ? `0${num}` : num
    }

    const formattedDate = `${formatNumbering(day)}/${formatNumbering(
      month
    )}/${year}`

    let formattedHours = hours % 12
    formattedHours = formattedHours || 12
    const period = hours < 12 ? 'AM' : 'PM'

    const formattedTime = `${formatNumbering(formattedHours)}:${formatNumbering(
      minutes
    )} ${period}`

    return `${formattedDate} ${formattedTime}`
  }

  return ''
}

export const getDateInWords = dateString => {
  const date = new Date(dateString)

  const year = date.getFullYear()
  const day = date.getDate()

  // Format the day with appropriate suffix
  let dayWithSuffix = day
  if (day >= 11 && day <= 20) {
    dayWithSuffix += 'th'
  } else {
    switch (day % 10) {
      case 1:
        dayWithSuffix += 'st'
        break
      case 2:
        dayWithSuffix += 'nd'
        break
      case 3:
        dayWithSuffix += 'rd'
        break
      default:
        dayWithSuffix += 'th'
        break
    }
  }

  // Format the month name
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  const monthName = monthNames[date.getMonth()]

  const formattedDate = `${monthName} ${dayWithSuffix}, ${year}`
  return formattedDate
}

export const initializeDateRange = () => {
  const now = new Date()
  const prevMonth = new Date()
  prevMonth.setMonth(now.getMonth() - 1)
  const endDate = dayjs(
    `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`
  )
  const startDate = dayjs(
    `${prevMonth.getFullYear()}-${
      prevMonth.getMonth() + 1
    }-${prevMonth.getDate()}`
  )
  return [startDate, endDate]
}

export const formatAmount = x => {
  if (x) {
    const formatted = `${x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
    return formatted.includes('.') ? formatted : `${formatted}.00`
  }
  return x
}

export const parseError = error => {
  if (error.code === 'ERR_NETWORK') {
    toast('Network error. Check your internet connection and try again.', {
      type: 'error',
      theme: 'colored'
    })
    return
  }

  if (error.code === 'ECONNABORTED') {
    toast('Request timed out. Check your internet connection and try again.', {
      type: 'error',
      theme: 'colored'
    })
    return
  }

  if (error.status === 401) {
    store.dispatch(authActions.logout())
    return
  }

  return error.response
}

export const getStateOfCountry = (stateCode, countryName) => {
  const countries = Country.getAllCountries()
  const country = countries.find(ctry => ctry.name.includes(countryName))
  if (country) {
    const state = State.getStateByCodeAndCountry(stateCode, country.isoCode)
    return state
  }
  return stateCode
}

export const scrollToTarget = (
  selector,
  container = window,
  headerOffset = 45
) => {
  const element = document.querySelector(selector)

  if (element) {
    const elementPosition = element.getBoundingClientRect().top
    const containerOffset = container ? container.scrollTop : window.pageYOffset

    let offsetPosition = elementPosition + containerOffset - headerOffset

    container.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    })
  }
}

export const setAuthCredsToCookies = credentials => {
  cookies.set('authToken', credentials.token, {
    expires: new Date(credentials.expire_at * 1000)
  })
  cookies.set('refreshToken', credentials.refresh_token)
  cookies.set('expireAt', credentials.expire_at * 1000)
}

export const urlQueryStringToObject = url =>
  Object.fromEntries([...new URLSearchParams(url.split('?')[1])])

export const capitalizeFirstLetter = string => {
  return string?.charAt(0).toUpperCase() + string?.slice(1)
}

export const sortArrayByDate = array => {
  array?.sort(function (a, b) {
    return new Date(a.created_at) - new Date(b.created_at)
  })
}

export const exportCSVData = (csvData, file_name) => {
  const url = window.URL.createObjectURL(new Blob([csvData]))
  const link = document.createElement('a')
  link.href = url

  link.setAttribute('download', file_name)
  document.body.appendChild(link)
  link.click()
  link.remove()
}
