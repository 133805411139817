import Modal from 'react-modal'
import confetti from '../../assets/confetti.svg'
import success from '../../assets/succes.png'
import { ReactComponent as Times } from '../../assets/times.svg'

const customStyles = {
  content: {
    inset: '16px',
    borderRadius: '20px',
    maxWidth: '768px',
    height: 'fit-content',
    maxHeight: '95%',
    margin: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function PaymentSuccessful ({
  isOpen,
  title = 'Successful!',
  description,
  secondaryDescription,
  onClose
}) {
  return (
    <Modal
      style={customStyles}
      isOpen={isOpen}
      appElement={document.getElementById('root')}
    >
      {onClose && (
        <div className='w-9 h-9 hover:border-solid hover:border-[0.75px] hover:border-gray-500 ml-auto cursor-pointer'>
          <Times onClick={onClose} />
        </div>
      )}
      <div className='relative'>
        <div className='flex flex-col items-center'>
          <img
            src={confetti}
            className='absolute top-4 left-1/2 -translate-x-1/2'
            alt='bg-confetti'
          />

          <div className='pt-14 pb-10 flex flex-col items-center w-full text-center'>
            <div className='mb-4'>
              <img src={success} alt='success' className='w-40' />
            </div>

            <div className='mb-8'>
              <h4 className='font-semibold mb-4'>{title}</h4>
              <p className='text-[#3A3A3AB2] text-sm max-w-sm'>{description}</p>
            </div>

            {secondaryDescription && (
              <div className='text-[#3A3A3A]'>{secondaryDescription}</div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}
