import Modal from 'react-modal'
import { ReactComponent as Times } from '../../../../assets/times.svg'
import { useState } from 'react'
import { BsInfoCircle } from 'react-icons/bs'

import ordersApi from '../../../../api/orders'
import useToast from '../../../../hooks/useToast'
import { parseError } from '../../../../utils'
import { CircularProgress } from '@mui/material'

const styles = {
  content: {
    inset: '16px',
    borderRadius: '20px',
    maxWidth: '450px',
    height: 'fit-content',
    margin: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export default function CreateMultipiece ({ isOpen, onClose, order, billTo }) {
  const [isCreatingMultipiece, setCreatingMultipiece] = useState(false)

  const toast = useToast()

  const createMultipiece = async () => {
    if (!isCreatingMultipiece) {
      setCreatingMultipiece(true)

      const multipiecePayload = {
        orders: [order.id],
        branch_name: order.branch_name
      }

      const response = await ordersApi.createMultipiece(multipiecePayload)

      setCreatingMultipiece(false)

      if (!response.ok) {
        const apiError = parseError(response)
        if (apiError) {
          let error = 'Unable to create multipiece order'
          if (apiError.status === 400) {
            error = apiError.data.detail[0]?.message
          }
          toast(error, 'error')
        }
        return
      }

      toast('Multipiece order created!', 'success')

      window.sessionStorage.setItem(
        'multipiece',
        JSON.stringify({
          ...response.data.payload,
          bill_to: billTo
        })
      )

      onClose(true, response.data.payload.id)
    }
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <Modal
      style={styles}
      isOpen={isOpen}
      appElement={document.getElementById('root')}
    >
      <div>
        <button
          className='w-9 h-9 hover:border-solid hover:border-[0.75px] hover:border-gray-500 cursor-pointer ml-auto flex'
          onClick={handleClose}
        >
          <Times />
        </button>
        <h3 className='text-lg text-dark-primary font-medium text-center mb-3'>
          Create Multipiece order
        </h3>
        <div>
          <div className='mb-5'>
            <p>
              You're now creating multiple orders to allow for all-in-one
              payment.
            </p>
          </div>

          <div className='mb-5'>
            <p className='mb-4'>
              This multipiece order will be billed to the{' '}
              <span className='font-medium'>
                {billTo} - {order[billTo].contact?.name}
              </span>
              .
            </p>
            <div className='flex gap-1 w-full'>
              <span className='w-4 block'>
                <BsInfoCircle size={14} className='mt-0.5' color='#33333399' />
              </span>
              <p className='text-sm text-dark-primary/60 w-[calc(100%_-_1.25rem)]'>
                You will not be able to change the selected customer in any of
                the subsequents orders in this multipiece.
              </p>
            </div>
          </div>

          <button
            className='btn btn-primary w-full'
            disabled={!billTo}
            onClick={createMultipiece}
          >
            {isCreatingMultipiece ? (
              <>
                Creating
                <CircularProgress
                  size={28}
                  color='inherit'
                  style={{ marginLeft: '0.5rem' }}
                />
              </>
            ) : (
              'Create'
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}
