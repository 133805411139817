import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import manifestApi from '../api/manifest'
import { parseError } from '../utils'

const initialState = {
  riders: null
}

export const fetchRiders = createAsyncThunk('riders/fetch', async () => {
  const response = await manifestApi.getRiders()

  if (!response.ok) {
    const apiError = parseError(response)
    if (apiError) {
      return {
        type: 'error',
        error: apiError
      }
    }
  }

  return response.data
})

const ridersSlice = createSlice({
  name: 'riders',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchRiders.fulfilled, (state, action) => {
      state.riders = action?.payload?.payload
    })
  }
})

export default ridersSlice.reducer
