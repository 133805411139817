import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Transition
} from '@headlessui/react'
import { RxCaretDown } from 'react-icons/rx'
import { getDate } from '../../utils'
import { reverseArr } from '../../helpers/reverseArr'
import { ReactComponent as Times } from '../../assets/times.svg'
import { useCallback, useMemo, useState } from 'react'
import useCountry from '../../hooks/useCountry'

export default function ShipmentStatus ({
  trackingID,
  trackingDetails,
  onClose
}) {
  const [heights, setHeights] = useState([])

  const Country = useCountry()

  const receiver = Country.getCountryByCode(
    trackingDetails.receiverLocation.country_code
  )

  const formatDateTime = (date, time) => {
    const formatNumbering = num => {
      return num < 10 ? `0${num}` : num
    }

    const period = time.hours < 12 ? 'AM' : 'PM'

    const formattedHours = time.hours % 12

    const formattedTime = `${formatNumbering(formattedHours)}:${formatNumbering(
      time.minutes
    )} ${period}`

    return `${date} ${formattedTime}`
  }

  const formatUPSDateTime = (date, time) => {
    date = `${date.slice(6)}/${date.slice(4, 6)}/${date.slice(0, 4)}`

    time = {
      hours: Number(time.slice(0, 2)),
      minutes: Number(time.slice(2, 4))
    }

    return formatDateTime(date, time)
  }

  const formatDHLDateTime = (date, time) => {
    date = `${date.slice(8)}/${date.slice(5, 7)}/${date.slice(0, 4)}`

    time = {
      hours: Number(time.slice(0, 2)),
      minutes: Number(time.slice(3, 5))
    }

    return formatDateTime(date, time)
  }

  const events = useMemo(() => {
    // sort by date and time
    switch (trackingDetails.tpl) {
      case 'DHL':
        return trackingDetails.events
      case 'FDX':
        return trackingDetails.events
      default:
        return reverseArr(trackingDetails.events)
    }
  }, [trackingDetails])

  const getBorderHeight = eventId => {
    const previousHeight = heights[eventId - 1]
    return previousHeight + 48
  }

  const stepRef = useCallback((node, id) => {
    if (node !== null) {
      setHeights(state => {
        state[id] = node.offsetHeight
        return state
      })
    }
  }, [])

  return (
    <div className='w-full max-w-[500px] p-6 pb-12 bg-white rounded-2xl'>
      <div
        className='w-6 h-6 hover:border-solid hover:border-[0.75px] hover:border-gray-500 ml-auto cursor-pointer flex items-center justify-center'
        onClick={onClose}
      >
        <Times className='w-6 h-6' />
      </div>
      <div className='mb-6'>
        <label className='text-[13px] opacity-80 mb-4'>Tracking Number</label>
        <div className='flex items-end flex-row gap-6 w-full'>
          <div className='border-b border-primary w-3/5'>
            <input
              className='border-none focus:border-none outline-0 focus:outline-0 py-1 px-0 text-base w-full'
              value={`${trackingID}`}
              readOnly
            />
          </div>

          <div className='flex items-center pr-4 py-1 border-b border-primary gap-3 w-2/5'>
            <span>{receiver.flag}</span>
            <span className='text-[0.65rem] opacity-70'>{receiver.name}</span>
          </div>
        </div>
      </div>
      <div>
        <div className='mb-4'>
          <h3 className='text-lg font-semibold mb-2'>
            {trackingDetails.tpl === 'AAJ LOCAL'
              ? trackingDetails.description
              : trackingDetails.status || trackingDetails.description}
          </h3>
          <div className='flex w-full justify-between gap-2'>
            <p>
              {trackingDetails.senderLocation.city},{' '}
              {trackingDetails.senderLocation.state},{' '}
              {trackingDetails.senderLocation.country}
            </p>
            <p className='whitespace-nowrap'>-----{'>'}</p>
            <p>
              {trackingDetails.receiverLocation.city},{' '}
              {trackingDetails.receiverLocation.state},{' '}
              {trackingDetails.receiverLocation.country}
            </p>
          </div>
          {trackingDetails.tpl !== 'AAJ LOCAL' && (
            <div className='mt-2'>
              <p className='text-g-600 text-sm'>
                Carrier Track No.: {trackingDetails.trackingNumber}
              </p>
            </div>
          )}
        </div>
        <div>
          <Disclosure as='div'>
            {({ open }) => (
              <>
                <DisclosureButton className='flex gap-2 items-center'>
                  <RxCaretDown
                    size={18}
                    className={`ml-2.5 transition-all -translate-x-1/2 ${
                      open ? 'rotate-0' : '-rotate-90'
                    }`}
                    color='#000000'
                  />
                  <span className='text-[12px] opacity-70'>
                    Click to {open ? 'collapse' : 'view tracking details'}
                  </span>
                </DisclosureButton>
                <Transition
                  enter='transition origin-top duration-150 ease-out'
                  enterFrom='transform scale-y-0 opacity-0'
                  enterTo='transform scale-y-100 opacity-100'
                  leave='transition origin-top duration-100 ease-out'
                  leaveFrom='transform scale-y-100 opacity-100'
                  leaveTo='transform scale-y-0 opacity-0'
                >
                  <DisclosurePanel
                    as='div'
                    className='py-5 overflow-y-auto max-h-[340px]'
                  >
                    <div aria-label='status stepper'>
                      {events.map((event, id) => {
                        return (
                          <div
                            ref={node => stepRef(node, id)}
                            aria-label='step'
                            className={`relative flex w-full items-start gap-4 ${
                              id !== 0 ? 'mt-12' : 'mt-0'
                            }`}
                            key={id}
                          >
                            {id !== 0 && (
                              <div
                                aria-label='connector'
                                className='absolute bottom-full left-2.5 -translate-x-1/2 border-l-2 border-dashed border-primary'
                                style={{
                                  height: getBorderHeight(id)
                                }}
                              ></div>
                            )}
                            <div
                              aria-label='pointer'
                              className='w-5 h-5 rounded-[50%] bg-primary'
                            ></div>
                            <div
                              aria-label='description'
                              className='text-sm w-[calc(100%-2.25rem)]'
                            >
                              <p className='font-medium mb-1'>
                                {event.derivedStatus ||
                                  event.status?.description ||
                                  event.description}
                              </p>
                              {event.eventDescription && (
                                <p className='mb-1'>{event.eventDescription}</p>
                              )}
                              <p className='text-[13px] mb-0.5'>
                                {trackingDetails.tpl === 'UPS'
                                  ? formatUPSDateTime(event.date, event.time)
                                  : trackingDetails.tpl === 'DHL'
                                  ? formatDHLDateTime(event.date, event.time)
                                  : trackingDetails.tpl === 'FDX'
                                  ? getDate(event.date)
                                  : getDate(event.date_time)}
                                {event.meta.user_name && (
                                  <>
                                    <br />
                                    Scanned by: {event.meta.user_name}
                                  </>
                                )}
                              </p>
                              {event.scanLocation ? (
                                <p>
                                  {event.scanLocation.city &&
                                    `${event.scanLocation.city},`}{' '}
                                  {event.scanLocation.countryName}
                                </p>
                              ) : event.meta ? (
                                <>
                                  <p>{event.meta.location}</p>
                                </>
                              ) : (
                                <p>
                                  {event.location?.address ||
                                    event.serviceArea[0]?.description}
                                </p>
                              )}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </DisclosurePanel>
                </Transition>
              </>
            )}
          </Disclosure>
        </div>
      </div>
    </div>
  )
}
