import { CircularProgress } from '@mui/material'
import AppFormField from '../globals/Form/AppFormField'
import AutoCompleteAddressField from '../globals/Form/AutoCompleteAddressField'
import AutoCompleteNameField from '../globals/Form/AutoCompleteNameField'
import AppForm from '../globals/Form/AppForm'
import { useEffect } from 'react'
import { useMemo } from 'react'
import { City, State } from 'country-state-city'
import { useFormikContext } from 'formik'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { capitalizeFirstLetter } from '../../utils'
import AppFormSelectField from '../globals/Form/AppFormSelectField'
import useCountry from '../../hooks/useCountry'
import nigerianStates from '../../fixtures/states'

export default function CustomerForm ({
  formik,
  phonecode,
  setPhonecode,
  customerType,
  isLocal,
  source = 'customer'
}) {
  const [stateNotListed, setStateNotListed] = useState(false)
  const [cityNotListed, setCityNotListed] = useState(false)
  const [provinceNotListed, setProvinceNotListed] = useState(false)

  const locations = useSelector(state => state.locations)

  const { values, isSubmitting } = useFormikContext()

  const Country = useCountry()

  const countries = Country.getAllCountries()

  const nigerianLocations = useMemo(() => {
    if (locations.data) {
      return nigerianStates.map(state => {
        const cities = locations.data.reduce((acc, location) => {
          if (state.name.toLowerCase() === location.state.toLowerCase()) {
            acc[capitalizeFirstLetter(location.city)] = location.provinces
          }
          return acc
        }, {})

        return {
          ...state,
          cities
        }
      })
    } else return []
  }, [locations.data])

  const states = useMemo(() => {
    if (values.code) {
      let states = []
      if (values.code === 'NG') {
        states = nigerianLocations
      } else {
        states = State.getStatesOfCountry(values.code)
      }

      if (values.state || values.state_name) {
        const stateIndex = states.findIndex(
          state =>
            state.isoCode === values.state || state.name === values.state_name
        )

        if (stateIndex === -1) {
          setStateNotListed(true)
        }
      }

      return states
    } else return []
  }, [nigerianLocations, values.code, values.state, values.state_name])

  const cities = useMemo(() => {
    if (values.state) {
      let cities = []

      if (values.code === 'NG') {
        let _cities = nigerianLocations.find(
          state =>
            state.isoCode === values.state ||
            state.name.toLowerCase() === values.state
        )?.cities

        if (_cities) {
          cities = Object.keys(_cities).sort((a, b) => a.localeCompare(b))
        } else {
          setCityNotListed(true)
        }
      } else {
        cities = City.getCitiesOfState(values.code, values.state).map(
          ({ name }) => name
        )
      }

      if (values.city) {
        const cityIndex = cities.findIndex(city => city === values.city)

        if (cityIndex === -1) {
          setCityNotListed(true)
        }
      }

      return cities
    } else return []
  }, [nigerianLocations, values.code, values.state, values.city])

  const provinces = useMemo(() => {
    if (values.code === 'NG') {
      let provinces = []
      if (values.city && values.state) {
        const state = nigerianLocations.find(
          state =>
            state.isoCode === values.state ||
            state.name.toLowerCase() === values.state
        )

        const cities = state?.cities[values.city]

        provinces = cities ? [...cities].sort((a, b) => a.localeCompare(b)) : []

        if (values.area_or_province) {
          const provinceIndex = provinces.findIndex(
            province => province === values.area_or_province
          )
          if (provinceIndex === -1) {
            setProvinceNotListed(true)
          }
        }
      }

      return provinces
    }
  }, [
    nigerianLocations,
    values.area_or_province,
    values.city,
    values.code,
    values.state
  ])

  useEffect(() => {
    if (values.code) {
      let phonecode = countries.find(
        ({ isoCode }) => isoCode === values.code
      )?.phonecode
      phonecode = phonecode ? phonecode.replace(/^(?!\+)/, '+') : ''
      setPhonecode(phonecode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.code])

  useEffect(() => {
    if (!stateNotListed) {
      let state_name = ''

      if (values.code === 'NG') {
        if (values.state) {
          state_name = states.find(
            ({ isoCode }) => isoCode === values.state
          )?.name
        }
      } else {
        if (values.state && values.code) {
          state_name = State.getStateByCodeAndCountry(
            values.state,
            values.code
          )?.name
        }
      }

      state_name && formik.setFieldValue('state_name', state_name)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.code, values.state])

  const onGetLocation = location => {
    if (location.country) {
      formik.setFieldValue('code', location.country.short_name)
    }
    if (location.state) {
      let state = ''
      let state_name = ''
      if (location.country.short_name === 'NG') {
        if (states) {
          const existingState = states.find(
            state =>
              state.name.toLowerCase() ===
              location.state.long_name.toLowerCase()
          )
          if (existingState) {
            state = existingState.isoCode
            state_name = existingState.name
          }
        }
      } else {
        state = location.state.short_name
        state_name = location.state.long_name
      }
      formik.setFieldValue('state', state)
      formik.setFieldValue('state_name', state_name)
    }
    if (location.city) {
      let city = ''
      if (location.country.short_name === 'NG') {
        if (cities) {
          const existingCity = cities.find(
            city => city.toLowerCase() === location.city.long_name.toLowerCase()
          )
          if (existingCity) {
            city = existingCity
          }
        }
      } else {
        city = location.city.long_name
      }
      formik.setFieldValue('city', city)
    }
    if (location.postal_code) {
      formik.setFieldValue('postcode', location.postal_code.long_name)
    }
  }

  return (
    <AppForm onSubmit={formik.handleSubmit} id='customer_form' padding='4'>
      <div className='flex flex-col gap-6 pt-4'>
        <AutoCompleteNameField
          name='full_name'
          title='Full Name *'
          placeholder='Full Name'
        />
        <AppFormField
          name='business_name'
          title={`Business name${
            customerType === 'receiver' && !isLocal ? ' *' : ''
          }`}
          placeholder='Business name'
          type='text'
        />
        <AutoCompleteAddressField
          name='address'
          title='Address *'
          placeholder='Address'
          onComplete={onGetLocation}
        />
        <AppFormField
          name='alt_address'
          title='Landmark/Apartment number'
          placeholder='Landmark/Apartment number'
          type='text'
        />
        <AppFormField
          name='alt_address_2'
          title='Address line 3'
          placeholder='Address line 3'
          type='text'
        />
        <AppFormSelectField
          name='code'
          title='Country *'
          disabled={source === 'editOrder'}
        >
          <option value=''>Select country</option>
          {countries.map((country, index) => (
            <option key={index} value={country.isoCode}>
              {country.name}
            </option>
          ))}
        </AppFormSelectField>
        <div className='w-full'>
          {stateNotListed ? (
            <div className='grid grid-cols-12 gap-2'>
              <div className='col-span-4'>
                <AppFormField
                  name='state'
                  title='State Code *'
                  placeholder='State Code'
                  disabled={source === 'editOrder'}
                />
              </div>
              <div className='col-span-8'>
                <AppFormField
                  name='state_name'
                  title='State Name *'
                  placeholder='State Name'
                  disabled={source === 'editOrder'}
                />
              </div>
            </div>
          ) : (
            <AppFormSelectField
              name='state'
              title='State *'
              disabled={source === 'editOrder'}
            >
              <option value=''>Select state</option>
              {states.map((state, index) => (
                <option key={index} value={state.isoCode}>
                  {state.name}
                </option>
              ))}
            </AppFormSelectField>
          )}
          <button
            className='text-info hover:text-secondary transition-all text-sm mt-2'
            onClick={() => setStateNotListed(!stateNotListed)}
            type='button'
          >
            {stateNotListed ? 'Select State' : 'State not listed?'}
          </button>
        </div>
        <div className='w-full'>
          {cityNotListed ? (
            <AppFormField
              name='city'
              title='City *'
              placeholder='City'
              disabled={source === 'editOrder'}
            />
          ) : (
            <AppFormSelectField
              name='city'
              title='City *'
              disabled={source === 'editOrder'}
            >
              <option value=''>Select city</option>
              {cities.map((city, index) => (
                <option key={index} value={city}>
                  {city}
                </option>
              ))}
            </AppFormSelectField>
          )}
          <button
            className='text-info hover:text-secondary transition-all text-sm mt-2'
            onClick={() => setCityNotListed(!cityNotListed)}
            type='button'
          >
            {cityNotListed ? 'Select City' : 'City not listed?'}
          </button>
        </div>
        {values.code === 'NG' && (
          <div>
            {provinceNotListed ? (
              <AppFormField
                name='area_or_province'
                title='Province or Area *'
                placeholder='Province or Area'
                disabled={source === 'editOrder'}
              />
            ) : (
              <AppFormSelectField
                name='area_or_province'
                title='Province or Area *'
                disabled={source === 'editOrder'}
              >
                <option value=''>Select</option>
                {provinces?.map((province, index) => (
                  <option key={index} value={province}>
                    {province}
                  </option>
                ))}
              </AppFormSelectField>
            )}
            <button
              className='text-info hover:text-secondary transition-all text-sm mt-2'
              onClick={() => setProvinceNotListed(!provinceNotListed)}
              type='button'
            >
              {provinceNotListed ? 'Select Province' : 'Province not listed?'}
            </button>
          </div>
        )}
        <AppFormField
          name='postcode'
          title='Postal Code'
          placeholder='Postal Code'
          type='text'
          min={4}
          max={12}
        />
        <AppFormField
          name='phone_number'
          title='Phone Number *'
          placeholder='Phone Number'
          type='number'
          phonecode={phonecode}
        />
        <AppFormField
          name='email'
          title='Email Address *'
          placeholder='Email'
          type='email'
        />
        <AppFormField
          name='business_contact'
          title='Business Contact'
          type='number'
          phonecode={phonecode}
        />
        <button
          className='btn-primary btn text-white'
          type='submit'
          form='customer_form'
        >
          {isSubmitting ? (
            <CircularProgress size={28} color='inherit' />
          ) : (
            'Submit'
          )}
        </button>
      </div>
    </AppForm>
  )
}
