import Modal from 'react-modal'
import { ReactComponent as Times } from '../../assets/times.svg'
import { ReactComponent as Line } from '../../assets/line.svg'
import { CircularProgress } from '@mui/material'
import { CopyText } from '../globals'

const styles = {
  content: {
    inset: '16px',
    maxWidth: '500px',
    margin: 'auto',
    height: 'fit-content',
    maxHeight: '95%',
    overflow: 'auto'
  },
  overlay: {
    backgroundColor: '#0000004f',
    zIndex: 60
  }
}

export const PaymentConfirmation = ({
  isOpen,
  onClose,
  onSent,
  loading,
  amount,
  salesforceAgent
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={loading ? null : onClose}
      style={styles}
      appElement={document.getElementById('root')}
    >
      <div className='px-4'>
        <div className='w-9 h-9 hover:border-solid hover:border-[0.75px] hover:border-gray-500 ml-auto cursor-pointer'>
          <Times onClick={onClose} />
        </div>
        <div className='text-center font-bold'>Payment Confirmation</div>
        <div className='text-center text-sm mt-6'>
          Make a transfer of{' '}
          <span className='font-medium'>
            {amount.toLocaleString('en-NG', {
              style: 'currency',
              currency: 'NGN'
            })}
          </span>{' '}
          to the agent's account number provided below. After making the
          transfer, click "I have sent It" to confirm payment.
        </div>
        <div className='flex justify-center w-full mt-4'>
          <Line />
        </div>
        <div className='flex justify-center w-full mt-4 text-sm'>
          <div className='w-full max-w-[320px] min-h-36 bg-[#F5F5F5] p-6 space-y-2'>
            <div className='flex gap-2 w-full'>
              <p>Account Name:</p>
              <p className='font-medium'>
                {salesforceAgent?.bank_details?.account_name}
              </p>
            </div>
            <div className='flex gap-2 w-full'>
              <p>Account Number:</p>
              <p className='font-medium flex gap-1.5 items-center'>
                {salesforceAgent?.bank_details?.account_number}
                <CopyText
                  text={salesforceAgent?.bank_details?.account_number}
                />
              </p>
            </div>
            <div className='flex gap-2 w-full'>
              <p>Bank:</p>
              <p className='font-medium'>
                {salesforceAgent?.bank_details?.bank}
              </p>
            </div>
            <div className='flex gap-2 w-full'>
              Amount:{' '}
              <p className='font-medium'>
                {amount.toLocaleString('en-Ng', {
                  style: 'currency',
                  currency: 'NGN'
                })}
              </p>
            </div>
          </div>
        </div>
        <div className='mt-8'>
          <button className='btn btn-primary w-full' onClick={onSent}>
            {loading ? (
              <CircularProgress size={28} color='inherit' />
            ) : (
              'I have sent it!'
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}
