import React, { useState, useEffect, useContext, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { capitalizeFirstLetter } from '../../utils'
import _ from 'lodash'
import { useLocation } from 'react-router-dom'
import { PiPrinterLight } from 'react-icons/pi'
import { IoIosSearch } from 'react-icons/io'
import noDataIcon from '../../assets/no-data.webp'
import { ReactComponent as Truck } from '../../assets/truck.svg'
import { ReactComponent as Check } from '../../assets/check.svg'
import { ReactComponent as Back } from '../../assets/goBack.svg'
import { fetchSingleManifest } from '../../slices/singleManifestSlice'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as User } from '../../assets/assign-user.svg'
import { Button } from '../../components'
import useToast from '../../hooks/useToast'
import IndividualDropdownOptions from '../../components/manifests/components/IndividualDropDownOption'
import { RaiseExceptionModal } from '../../components/manifests/components/RaiseExceptionModal'
import Page from '../../containers/Page'
import manifestApi from '../../api/manifest'
import SearchManifest from '../../components/manifests/components/SearchManifest'
import StateButton from '../../components/manifests/components/StateButton'
import { fetchAllManifestShipments } from '../../slices/allInManifestShipments'
import ROLES from '../../constants/roles'
import { AuthLayoutContext } from '../../containers/AuthLayout'

const IndividualCenterManifest = ({ metaTitle }) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const toast = useToast()
  const allData = useSelector(state => state?.singleManifest?.singleManifest)
  const AddmanifestShipment = useSelector(state => state?.allShipments)
  const ridersData = useSelector(state => state?.riders?.riders)
  const userData = useSelector(state => state.auth.user)
  const [openModal, setOpenModal] = useState(false)
  const [openFilterModal, setOpenFilterModal] = useState(false)
  const [riderId, setRiderId] = useState('')
  const [searchVal, setSearchVal] = useState('')
  const [selected, setSelected] = useState(false)
  const [riderName, setRiderName] = useState('')
  const [loading, setLoading] = useState(false)
  const [waybillData, setWaybillData] = useState('')
  const [data, setData] = useState([])
  const [shipmentData, setShipmentData] = useState([])
  const [selectedShipment, setSelectedShipment] = useState([])
  const [destination, setDestination] = useState('')
  const [riderVal, setRiderVal] = useState([])
  const [riderSearchedVal, setRiderSearchedVal] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    if ((ridersData?.length !== 0) & (riderSearchedVal === '')) {
      setRiderVal(ridersData)
    }
  }, [ridersData, riderSearchedVal])

  const findRider = value => {
    if (value?.length !== 0) {
      const searchData = ridersData?.filter(item => item?.name?.includes(value))
      setRiderVal(searchData)
    } else {
      setRiderVal(ridersData)
    }
  }

  const removeSelected = id => {
    const rmSel = selectedShipment?.filter(item => item !== id)
    setSelectedShipment(rmSel)
  }

  const selectAllShipment = () => {
    let emd = []
    shipmentData?.map(item => {
      emd.push(item?.tracking_id)
    })
    setSelectedShipment([...emd])
  }
  const selectShipment = value => {
    selectedShipment.push(value)
    setSelectedShipment([...selectedShipment])
  }

  // useEffect(() => {
  //   if (shipmentsStore?.data?.length !== 0) {
  //     const shipment = shipmentsStore?.data?.filter(
  //       item => item?.in_manifest !== true
  //     )
  //     setShipmentData(shipment)
  //   }
  // }, [shipmentsStore?.data])

  // const searchShipment = value => {
  //   if (value?.length !== 0) {
  //     const shipment = shipmentsStore?.data?.filter(
  //       item =>
  //         item?.in_manifest !== true &&
  //         item?.tracking_id?.includes(value?.toUpperCase())
  //     )
  //     setShipmentData(shipment)
  //   } else {
  //     const shipment = shipmentsStore?.data?.filter(
  //       item => item?.in_manifest !== true
  //     )
  //     setShipmentData(shipment)
  //   }
  // }

  useEffect(() => {
    if ((allData?.length !== 0) & (searchVal === '')) {
      setData(allData?.meta?.shipments)
    }
  }, [allData, searchVal])

  const handleSearch = value => {
    if (value?.length !== 0) {
      const searchData = data?.filter(item =>
        item?.includes(value?.toUpperCase())
      )
      setSearchVal(value)
      setData(searchData)
    } else {
      setData(allData?.meta?.shipments)
      setSearchVal('')
    }
  }

  const handleFilter = () => {
    if (destination !== '') {
      const searchData = allData?.meta?.shipments?.filter(
        item => item?.destination_hub_name === destination
      )
      setSearchVal(destination)
      setData(searchData)
    } else {
      setData(allData)
      setSearchVal(destination)
    }
  }

  const addShipment = async () => {
    setLoading(true)
    const result = await manifestApi.addShioment({
      manifest_id: allData?.id,
      shipments: selectedShipment,
      manifest_type: 'CM'
    })

    if (result.ok) {
      toast('Shipment added', 'success')
      setLoading(false)
      dispatch(fetchSingleManifest(`CM/${allData?.id}`))
    }

    if (!result.ok) {
      setLoading(false)
      toast(result?.problem, 'error')
    }
  }

  const removeShipment = async () => {
    setLoading(true)
    const result = await manifestApi.removeShioment({
      manifest_id: allData?.id,
      shipments: [waybillData],
      manifest_type: 'CM'
    })

    if (result.ok) {
      toast('Shipment removed', 'success')
      setLoading(false)
      dispatch(fetchSingleManifest(`CM/${allData?.id}`))
    }

    if (!result.ok) {
      setLoading(false)
      toast(result?.problem, 'error')
    }
  }

  const publishManifest = async () => {
    setLoading(true)
    const result = await manifestApi.publishManifest(allData?.tracking_id)

    if (result.ok) {
      toast('Manifest published', 'success')
      setLoading(false)
      dispatch(fetchSingleManifest(`CM/${allData?.id}`))
    }

    if (!result.ok) {
      setLoading(false)
      toast(result?.problem, 'error')
    }
  }

  useEffect(() => {
    dispatch(fetchSingleManifest(`CM/${location?.state?.id}`))
  }, [dispatch, location?.state?.id])

  const handleAssign = async () => {
    setLoading(true)
    const result = await manifestApi.assignManifest(allData?.id, {
      manifest_type: 'CM',
      rider: { id: riderId, name: riderName }
    })

    if (result.ok) {
      toast('Rider assigned', 'success')
      setLoading(false)
    }

    if (!result.ok) {
      setLoading(false)
      toast(result?.problem, 'error')
    }
  }

  const gotoPrint = () => {
    navigate(`/manifest/print_center`)
  }

  const getShipmentsToAdd = () => {
    dispatch(
      fetchAllManifestShipments({
        page_size: 50,
        branch: userData?.branch?.name,
        order_type: 'LC',
        status: 1,
        in_manifest: 'False',
        page: 1,
        tpl: allData?.meta?.tpl_service
      })
    )
  }
  const { userRole } = useContext(AuthLayoutContext)

  const canCreateManifest = useMemo(() => {
    if (userRole) {
      return ROLES[userRole.id].permissions?.manifest?.actions?.createManifest
    }
  }, [userRole])

  return (
    <Page metaTitle={metaTitle}>
      <Page.Header title={'Manifest/' + allData?.tracking_id?.toUpperCase()}>
        <SearchManifest
          handleSearch={handleSearch}
          setOpenModal={setOpenFilterModal}
        />
      </Page.Header>
      <Page.Body>
        <div className='relative w-full'>
          {/* end of sticky nav */}
          <div className='w-full flex justify-between items-center mt-14'>
            <Back
              onClick={() => navigate(`/manifest/cm`)}
              className='cursor-pointer'
            />
            <div className='flex justify-around items-center space-x-2'>
              {allData?.state === 'DRAFT' && (
                <div>
                  {canCreateManifest && (
                    <Popover>
                      <PopoverButton>
                        <Button
                          bgColor='#E8E8E8'
                          text='Add Shipment'
                          color='#3A3A3A'
                          size='text-md'
                          borderRadius='10px'
                          onClick={getShipmentsToAdd}
                        />
                      </PopoverButton>

                      <PopoverPanel
                        className={`absolute mt-2 border-none outline-none z-30`}
                      >
                        {({ close }) => (
                          <div className='overflow-hidden shadow-lg w-[300px] pb-4 bg-white z-10'>
                            <div className='flex w-full p-4 z-10'>
                              <input
                                type='text'
                                placeholder='Search for shipment'
                                className='w-[85%] h-[36px] txtinp bg-white border-[1px] border-[#E5E4E5] rounded-l-xl'
                                //onChange={e => searchShipment(e.target.value)}
                              />
                              <span className='w-[15%] h-[36px] bg-[#E5E4E5] rounded-r-xl flex justify-center items-center'>
                                <IoIosSearch className='w-[24px] h-[24px]' />
                              </span>
                            </div>
                            <div
                              className='flex w-full justify-end px-2 py-4 cursor-pointer'
                              onClick={selectAllShipment}
                            >
                              Select all
                            </div>
                            <div className='overflow-y-scroll h-[150px]'>
                              {AddmanifestShipment?.data?.length === 0 ? (
                                <div className='w-full flex justify-center items-center'>
                                  <div className='pt-14 w-full flex flex-col items-center justify-center'>
                                    <img
                                      src={noDataIcon}
                                      className='w-20 h-20 object-contain'
                                      alt='no data icon'
                                    />
                                    <p className='text-gray-500 text-left my-2 font-semibold text-base md:text-sm'>
                                      No shipment found
                                    </p>
                                  </div>
                                </div>
                              ) : (
                                AddmanifestShipment?.data?.map((data, id) => (
                                  <span
                                    className='flex items-center space-x-4 p-4 cursor-pointer hover:bg-[#FCE3C7]'
                                    key={id}
                                    onClick={() => {
                                      if (
                                        !selectedShipment?.includes(
                                          data?.tracking_id
                                        )
                                      ) {
                                        selectShipment(data?.tracking_id)
                                      } else {
                                        removeSelected(data?.tracking_id)
                                      }
                                    }}
                                  >
                                    {selectedShipment?.includes(
                                      data?.tracking_id
                                    ) ? (
                                      <>
                                        <Check />{' '}
                                        <p>
                                          {!data?.in_manifest &&
                                            data?.tracking_id}
                                        </p>
                                      </>
                                    ) : (
                                      <p>
                                        {!data?.in_manifest &&
                                          data?.tracking_id}
                                      </p>
                                    )}
                                  </span>
                                ))
                              )}
                            </div>
                            <div className='w-full flex justify-end mt-12'>
                              <span
                                className='flex space-x-2 justify-center items-center w-[125px] h-[50px] bg-main-primary rounded-[100px] cursor-pointer'
                                onClick={() => {
                                  addShipment()
                                  setTimeout(() => {
                                    close()
                                  }, 1000)
                                }}
                              >
                                <p className='text-white text-xs'>
                                  {loading === true
                                    ? 'Loading...'
                                    : 'Add ' +
                                      _.sortedUniqBy(selectedShipment, e => e)
                                        ?.length}
                                </p>
                              </span>
                            </div>
                          </div>
                        )}
                      </PopoverPanel>
                    </Popover>
                  )}
                </div>
              )}
              {allData?.state === 'DRAFT' && (
                <div>
                  {canCreateManifest && (
                    <StateButton
                      bgColor='#E8E8E8'
                      text='Publish'
                      color='#3A3A3A'
                      size='text-md'
                      borderRadius='10px'
                      loading={loading}
                      handlePublish={publishManifest}
                    />
                  )}
                </div>
              )}

              <Button
                onClick={gotoPrint}
                bgColor='#E8E8E8'
                text='Print'
                color='#3A3A3A'
                size='text-md'
                borderRadius='10px'
                icon={<PiPrinterLight />}
              />
              {canCreateManifest && (
                <div>
                  {allData?.assigned_to === null ? (
                    <Popover className='z-20'>
                      <PopoverButton>
                        <Button
                          bgColor='#FF4D00'
                          text='Assign'
                          color='white'
                          size='text-md'
                          borderRadius='10px'
                          icon={<User stroke='white' />}
                        />
                      </PopoverButton>

                      <PopoverPanel
                        className={`absolute mt-2 border-none outline-none z-10 right-2`}
                      >
                        {({ close }) => (
                          <div className='overflow-hidden shadow-lg w-[300px] pb-4 bg-white z-10'>
                            <div className='flex w-full p-4'>
                              <input
                                type='text'
                                placeholder='Search for a captain or astronaut'
                                className='w-[85%] h-[36px] txtinp bg-white border-[1px] border-[#E5E4E5] rounded-l-xl'
                                onChange={e => {
                                  findRider(e.target.value)
                                }}
                              />
                              <span className='w-[15%] h-[36px] bg-[#E5E4E5] rounded-r-xl flex justify-center items-center'>
                                <IoIosSearch className='w-[24px] h-[24px]' />
                              </span>
                            </div>
                            <div className='overflow-y-scroll h-[250px]'>
                              {riderVal?.map(rider => (
                                <span
                                  className={`flex items-center space-x-4 p-4 cursor-pointer hover:bg-[#FCE3C7] ${
                                    riderId === rider?.id
                                      ? 'bg-main-hover'
                                      : 'bg-white'
                                  }`}
                                  onClick={() => {
                                    setSelected(!selected)
                                    setRiderId(rider.id)
                                    localStorage.setItem(
                                      'riderName',
                                      rider.name
                                    )
                                  }}
                                >
                                  <Truck />
                                  <p onClick={() => setRiderName(rider.name)}>
                                    {rider.name}
                                  </p>
                                </span>
                              ))}
                            </div>

                            <div className='w-full flex justify-end mt-12'>
                              <span
                                className='flex space-x-2 justify-center items-center w-[125px] h-[50px] bg-main-primary rounded-[100px] cursor-pointer'
                                onClick={() => {
                                  handleAssign()
                                  setTimeout(() => {
                                    close()
                                  }, 2000)
                                }}
                              >
                                <p className='text-white text-xs'>
                                  {loading === true ? 'Loading...' : 'Assign'}
                                </p>
                              </span>
                            </div>
                          </div>
                        )}
                      </PopoverPanel>
                    </Popover>
                  ) : (
                    <Popover className='z-20'>
                      <PopoverButton>
                        <Button
                          bgColor='#FF4D00'
                          text='Reassign'
                          color='white'
                          size='text-md'
                          borderRadius='10px'
                          icon={<User stroke='white' />}
                        />
                      </PopoverButton>

                      <PopoverPanel
                        className={`absolute mt-2 border-none outline-none z-10 right-2`}
                      >
                        {({ close }) => (
                          <div className='overflow-hidden shadow-lg w-[300px] pb-4 bg-white z-10'>
                            <div className='flex w-full p-4'>
                              <input
                                type='text'
                                placeholder='Search for a captain or astronaut'
                                className='w-[85%] h-[36px] txtinp bg-white border-[1px] border-[#E5E4E5] rounded-l-xl'
                                onChange={e => {
                                  findRider(e.target.value)
                                }}
                              />
                              <span className='w-[15%] h-[36px] bg-[#E5E4E5] rounded-r-xl flex justify-center items-center'>
                                <IoIosSearch className='w-[24px] h-[24px]' />
                              </span>
                            </div>
                            <div className='overflow-y-scroll h-[250px]'>
                              {riderVal?.map(rider => (
                                <span
                                  className={`flex items-center space-x-4 p-4 cursor-pointer hover:bg-[#FCE3C7] ${
                                    riderId === rider?.id
                                      ? 'bg-main-hover'
                                      : 'bg-white'
                                  }`}
                                  onClick={() => {
                                    setSelected(!selected)
                                    setRiderId(rider.id)
                                    localStorage.setItem(
                                      'riderName',
                                      rider.name
                                    )
                                  }}
                                >
                                  <Truck />
                                  <p onClick={() => setRiderName(rider.name)}>
                                    {rider.name}
                                  </p>
                                </span>
                              ))}
                            </div>

                            <div className='w-full flex justify-end mt-12'>
                              <span
                                className='flex space-x-2 justify-center items-center w-[125px] h-[50px] bg-main-primary rounded-[100px] cursor-pointer'
                                onClick={() => {
                                  handleAssign()
                                  setTimeout(() => {
                                    close()
                                  }, 2000)
                                }}
                              >
                                <p className='text-white text-xs'>
                                  {loading === true ? 'Loading...' : 'Reassign'}
                                </p>
                              </span>
                            </div>
                          </div>
                        )}
                      </PopoverPanel>
                    </Popover>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className='mt-8'>
            {searchVal?.length !== 0 && (
              <>
                Showing results for <b>{searchVal}</b>
              </>
            )}
          </div>

          {data?.length === 0 ? (
            <div className='w-full flex justify-center items-center'>
              <div className='pt-14 w-full flex flex-col items-center justify-center'>
                <img
                  src={noDataIcon}
                  className='w-40 h-40 object-contain'
                  alt='no data'
                />
                <p className='text-gray-500 text-left my-2 font-semibold text-base md:text-xl'>
                  No shipment found
                </p>
              </div>
            </div>
          ) : (
            <>
              <div className='w-full overflow-x-auto rounded-lg border border-[#EFEFEF] pb-48'>
                <table className='table--hover'>
                  <thead>
                    <tr>
                      <th>SN</th>
                      <th>Waybill Numbers</th>
                      <th>Origin </th>
                      <th>Destination</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((data, id) => (
                      <tr key={id} className='cursor-pointer'>
                        <td>{id + 1}</td>
                        <td>{data?.toUpperCase()}</td>
                        <td>
                          {capitalizeFirstLetter(allData?.originating_center)}
                        </td>
                        <td>
                          {capitalizeFirstLetter(allData?.destination_hub_name)}
                        </td>

                        <td>
                          {allData?.state === 'DRAFT' && (
                            <div>
                              {canCreateManifest && (
                                <IndividualDropdownOptions
                                  sn={id + 1}
                                  id={id}
                                  setOpenModal={setOpenModal}
                                  tracking_id={allData?.tracking_id}
                                  clickThreeDots={() => setWaybillData(data)}
                                  removeShipment={() => removeShipment()}
                                  loading={loading}
                                />
                              )}
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
          <RaiseExceptionModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            waybillData={waybillData}
            type='SHIPMENT'
            location={allData?.destination_hub_name}
            hub={allData?.destination_hub}
            removeShipment={() => removeShipment()}
          />
        </div>
      </Page.Body>
    </Page>
  )
}

export default IndividualCenterManifest
