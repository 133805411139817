import { useCallback, useEffect, useMemo, useState } from 'react'
import Page from '../../containers/Page'
import { getDate, initializeDateRange } from '../../utils'
import { useDispatch, useSelector } from 'react-redux'
import { PaymentConfirmation } from '../../components/salesforce/PaymentConfirmation'
import PaymentSuccessful from '../../components/salesforce/PaymentSuccessful'
import salesforceApi from '../../api/salesforce'
import useToast from '../../hooks/useToast'
import { useParams, useSearchParams } from 'react-router-dom'
import {
  fetchSalesforceAgent,
  fetchSalesforceAgentCommisions,
  salesforceActions
} from '../../slices/salesforceSlice'
import ROUTES from '../../constants/routes'
import Table from '../../components/globals/Table'
import Pagination from '../../components/globals/pagination/ServerPagination'
import Pill from '../../components/globals/Pill'
import Search from '../../components/globals/Search/Search'
import SearchResultsDescription from '../../components/globals/Search/SearchResultsDescription'
import Filter from '../../components/globals/filter/Filter'
import { resolveDateParams } from '../../helpers/queryByDate'
import FilterTag from '../../components/globals/filter/FilterTag'
import { SalesforceAgentCommissionsFilter } from '../../components/salesforce/SalesforceFilter'
import { Loader } from '../../components/globals'

const breadCrumbs = [
  {
    name: 'Salesforce',
    path: ROUTES.SALESFORCE.path
  },
  {
    name: 'Agent',
    path: '#',
    disabled: true
  }
]

export default function SalesForceAgent ({ metaTitle }) {
  const { id } = useParams()
  const [searchParams] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const [isSalesforceModal, setSalesforceModal] = useState(false)
  const [isSuccessModal, setSuccessModal] = useState(false)
  const [serializedData, setSerializedData] = useState()
  const [searchValue, setSearchValue] = useState('')
  const [serverSearch, setServerSearch] = useState(null)
  const [filterTags, setFilterTags] = useState([])
  const [queryParams, setQueryParams] = useState({
    page: 1,
    page_size: 50
  })
  const [filter, setFilter] = useState({
    date_type: '', // order_date | paid_date
    start_date: '', // dd-mm-yy
    end_date: '', // dd-mm-yy
    status: '' // unpaid | paid
  })
  const [checkedList, setCheckedList] = useState([])
  const [dateRange, setDateRange] = useState(initializeDateRange())

  const { data: salesforceAgent, commissions: commissionsStore } = useSelector(
    state => state.salesforce.salesforceAgent
  )

  const agentName = searchParams.get('full_name')
  const agentCode = searchParams.get('code')
  const toast = useToast()
  const dispatch = useDispatch()

  const loadSalesForceAgentData = useCallback(() => {
    dispatch(fetchSalesforceAgent(id))
    dispatch(fetchSalesforceAgentCommisions({ id, params: queryParams }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, queryParams])

  useEffect(() => {
    setSerializedData(null)
    loadSalesForceAgentData()
  }, [loadSalesForceAgentData])

  useEffect(() => {
    return () => {
      dispatch(salesforceActions.cleanupAgent())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const params = {}
    const tags = []

    for (const key in filter) {
      if (filter[key]) {
        if (key === 'date') {
          let tag = { name: key, value: '' }
          const { start_date, end_date } = resolveDateParams(
            filter.date,
            dateRange
          )
          params.start_date = start_date
          params.end_date = end_date
          if (filter.date === 'range') {
            tag.value = `From: ${dateRange[0]
              .format()
              .slice(0, 10)}, To: ${dateRange[1].format().slice(0, 10)}`
          } else {
            tag.value = filter[key].replaceAll('_', ' ')
          }
          tags.push(tag)
        } else {
          params[key] = filter[key]
          let tag = { name: key, value: filter[key].replaceAll('_', ' ') }
          tags.push(tag)
        }
      }
    }

    const query = { ...queryParams, ...params }
    query.page = 1

    setQueryParams(query)

    setFilterTags(tags)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, filter])

  const markAsPaidSalesforce = async () => {
    setLoading(true)

    let response = await salesforceApi.markAsPaid(salesforceAgent.id, {
      ids: checkedList.map(({ id }) => id)
    })
    setLoading(false)

    if (!response.ok) {
      toast('Error completing payment for commissions', 'error')
      return
    }

    setSalesforceModal(false)
    setSuccessModal(true)
  }

  const onPage = params => {
    setSerializedData(null)
    setQueryParams(state => ({ ...state, ...params }))
  }

  const tableHeader = [
    'S/N',
    'Order ID',
    'Customer',
    'Order Date',
    'Amount Paid',
    'Percentage',
    'Commission',
    'Date Paid',
    'Status'
  ]

  const tableData = useMemo(() => {
    if (serializedData) {
      let results = serializedData

      return results.map(commission => ({
        ...commission,
        'S/N': commission.s_n,
        'Order ID': commission.order,
        Customer: commission.customer_name,
        'Order Date': getDate(commission.order_date),
        'Amount Paid': commission.amount_paid.toLocaleString('en-NG', {
          style: 'currency',
          currency: 'NGN'
        }),
        Percentage: `${commission.commission_percent}%`,
        Commission: commission.commission_amount.toLocaleString('en-NG', {
          style: 'currency',
          currency: 'NGN'
        }),
        'Date Paid': getDate(commission.date_paid),
        Status: (
          <Pill
            name={commission.status}
            theme={{
              primary: commission.status === 'unpaid' ? '#FFC700' : '#00AB44',
              secondary:
                commission.status === 'unpaid' ? '#FFF6D799' : '#00AB4433'
            }}
          />
        ),
        checkDisabled: commission.status === 'paid'
      }))
    }
    return null
  }, [serializedData])

  const credentials = useMemo(
    () => [
      {
        key: 'Email Address',
        value: salesforceAgent?.email
      },
      {
        key: 'Phone Number',
        value: salesforceAgent?.phone
      },
      {
        key: 'Commission Rate',
        value: `${salesforceAgent?.commission}%`
      },
      {
        key: 'Total Orders',
        value: salesforceAgent?.total_orders.toLocaleString()
      },
      {
        key: 'Total Commission Earned',
        value: salesforceAgent?.total_commission_amount.toLocaleString(
          'en-NG',
          {
            style: 'currency',
            currency: 'NGN'
          }
        )
      },
      {
        key: 'Total Commission Paid',
        value: salesforceAgent?.total_paid_commission_amount.toLocaleString(
          'en-NG',
          {
            style: 'currency',
            currency: 'NGN'
          }
        )
      },
      {
        key: 'Total Commission Unpaid',
        value: salesforceAgent?.total_unpaid_commission_amount.toLocaleString(
          'en-NG',
          {
            style: 'currency',
            currency: 'NGN'
          }
        )
      }
    ],
    [salesforceAgent]
  )

  const amount = useMemo(() => {
    return checkedList.reduce(
      (acc, { commission_amount }) => acc + commission_amount,
      0
    )
  }, [checkedList])

  const onSearchChange = ({ target }) => {
    setSearchValue(target.value)
  }

  const handleServerSearch = () => {
    setQueryParams(state => ({ ...state, customer_name: searchValue }))
    setServerSearch({
      searchBy: 'Customer',
      searchValue
    })
  }
  const onCloseServerSearch = () => {
    setServerSearch(null)
    const query = { ...queryParams }
    delete query.customer_name
    setQueryParams(query)
  }

  const onFilterDelete = key => {
    setFilter(state => ({
      ...state,
      [key]: ''
    }))

    let query = { ...queryParams }
    if (key === 'date') {
      delete query.start_date
      if (filter[key] !== 'today') delete query.end_date
      if (filter[key] === 'range') setDateRange(initializeDateRange())
    } else delete query[key]

    setQueryParams(query)
  }

  const handleSelectItem = list => {
    const itemsList = []

    for (let s_n in list) {
      // eslint-disable-next-line eqeqeq
      let existingItem = checkedList.find(item => item.s_n == s_n)
      if (existingItem) {
        itemsList.push(existingItem)
        continue
      }

      // eslint-disable-next-line eqeqeq
      let customer = serializedData.find(item => item.s_n == s_n)
      itemsList.push(customer)
    }

    setCheckedList(itemsList)
  }

  const onPay = () => {
    setSalesforceModal(true)
  }

  const handleCloseSalesforceModal = () => {
    setSalesforceModal(false)
  }

  const handleCloseSuccessModal = () => {
    loadSalesForceAgentData()
    setSuccessModal(false)
  }

  return (
    <Page metaTitle={metaTitle}>
      <Page.Header
        title={
          <>
            {agentName}
            <span className='text-primary font-normal inline-block ml-2'>
              #{agentCode}
            </span>
          </>
        }
        breadCrumbs={breadCrumbs}
        backRoute={ROUTES.SALESFORCE.path}
      >
        <Search
          value={searchValue}
          inputPlaceHolder={'Search customer name'}
          handleSearch={onSearchChange}
          allowServerSearch={true}
          onServerSearch={handleServerSearch}
        />
        <Filter
          Component={SalesforceAgentCommissionsFilter}
          filter={filter}
          setFilter={setFilter}
          dateRange={dateRange}
          setDateRange={setDateRange}
        />
      </Page.Header>
      <Page.Body>
        {serverSearch && (
          <div className='mb-3 flex flex-col gap-2 lg:gap-3'>
            <div className='flex items-center gap-2 lg:gap-3'>
              <SearchResultsDescription
                searchState={serverSearch}
                onClose={onCloseServerSearch}
              />
            </div>
          </div>
        )}
        {!salesforceAgent ? (
          <div className='py-14'>
            <Loader page={false} />
          </div>
        ) : (
          <>
            <div>
              <div className='text-sm py-4 flex w-full flex-col md:flex-row md:gap-10 items-start'>
                <div className='space-y-1 mb-1'>
                  {credentials.slice(0, 3).map(({ key, value }, id) => (
                    <div className='flex gap-2 w-full' key={id}>
                      <p className='w-52'>{key}:</p>
                      <p className='w-[calc(100%_-_13rem)] font-medium break-all'>
                        {value}
                      </p>
                    </div>
                  ))}
                </div>
                <div className='space-y-1 mb-1'>
                  {credentials.slice(4).map(({ key, value }, id) => (
                    <div className='flex gap-2 w-full' key={id}>
                      <p className='w-52'>{key}:</p>
                      <p className='w-[calc(100%_-_13rem)] font-medium break-words'>
                        {value}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {commissionsStore.data && (
              <div className='mt-8'>
                <div className='flex w-full justify-between gap-2'>
                  {!!filterTags.length && (
                    <div className='flex items-center gap-2 flex-wrap mb-3'>
                      <p className='text-base font-medium text-dark-primary'>
                        Filter:
                      </p>
                      {filterTags.map(({ name, value }, id) => (
                        <FilterTag
                          key={id}
                          name={name}
                          value={value}
                          onDelete={onFilterDelete}
                        />
                      ))}
                    </div>
                  )}
                  <div className='ml-auto block md:hidden'>
                    <Pagination
                      tableId='salesforce_agent_commissions-table'
                      pageSize={commissionsStore.meta?.page_size}
                      totalCount={commissionsStore.meta?.count}
                      data={commissionsStore.data}
                      setSerializedData={setSerializedData}
                      onPage={onPage}
                      page={commissionsStore.meta?.page}
                      reverse={true}
                    />
                  </div>
                </div>

                <div className='bg-white  border border-[#EFEFEF] rounded-lg overflow-hidden'>
                  <div className='w-full flex justify-between bg-white border-b border-[#878787]'>
                    <div className='w-1/2 md:w-3/4 flex justify-between items-center p-2 md:pl-4 md:pr-6 py-2'>
                      <h4 className='font-medium'>Transactions</h4>
                      <div className='flex items-center gap-2'>
                        <div className='hidden md:block'>
                          <Pagination
                            tableId='salesforce_agent_commissions-table'
                            pageSize={commissionsStore.meta?.page_size}
                            totalCount={commissionsStore.meta?.count}
                            data={commissionsStore.data}
                            setSerializedData={setSerializedData}
                            onPage={onPage}
                            page={commissionsStore.meta?.page}
                            reverse={true}
                          />
                        </div>
                        <h5>Total</h5>
                      </div>
                    </div>
                    <div className='w-1/2 md:w-1/4 flex justify-between items-center border-l border-[#878787] p-2'>
                      <div className='font-semibold'>
                        {amount.toLocaleString('en-NG', {
                          style: 'currency',
                          currency: 'NGN'
                        })}
                      </div>
                      <button
                        className='btn btn-primary btn-sm md:btn-md md:py-3 md:px-8'
                        disabled={!checkedList.length}
                        onClick={onPay}
                      >
                        Pay
                      </button>
                    </div>
                  </div>

                  <Table
                    variant='flat'
                    id='salesforce_agent_commissions-table'
                    headers={tableHeader}
                    data={tableData}
                    emptyDataText='No commission found'
                    withCheckbox
                    onSelectRow={handleSelectItem}
                  />
                </div>
              </div>
            )}

            {isSalesforceModal && (
              <PaymentConfirmation
                isOpen={isSalesforceModal}
                onClose={handleCloseSalesforceModal}
                onSent={markAsPaidSalesforce}
                loading={loading}
                amount={amount}
                salesforceAgent={salesforceAgent}
              />
            )}
            {isSuccessModal && (
              <PaymentSuccessful
                isOpen={isSuccessModal}
                onClose={handleCloseSuccessModal}
                amount={amount}
                agentName={agentName}
              />
            )}
          </>
        )}
      </Page.Body>
    </Page>
  )
}
