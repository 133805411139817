import React from 'react'

const Button = ({
  bgColor = '#FF4D00',
  color = '#ffffff',
  size = 'sm',
  text,
  borderRadius = '6px',
  icon,
  onClick
}) => {
  return (
    <button
      onClick={onClick}
      type='button'
      style={{ backgroundColor: bgColor, color, borderRadius }}
      className={`text-${size} px-5 py-3 hover:drop-shadow-xl space-x-2 flex items-center justify-center cursor-pointer`}
    >
      {icon} <p className='font-medium'>{text}</p>
    </button>
  )
}

export default Button
